import { Component, OnInit, Input } from '@angular/core';
import { CmsService } from 'src/_service/CmsService';

@Component({
    selector: 'app-cms-sticker',
    templateUrl: './cms-sticker.component.html',
    standalone: true
})
export class CmsStickerComponent {

  @Input() sticker: any;

  constructor(private _cmsService: CmsService) {
  }

  // render_rt(content) {
  //   return this._cmsService.render_rt(content);
  // }

  get_alt(field) {
    return this._cmsService.get_alt(field);
  }

  get_src(field, contain, height?, width?) {
    return this._cmsService.get_src(field, contain, height, width);
}

  get_classes(card) {
    return this._cmsService.get_classes(card);
  }

}



