<div class="bg-e-white">
  <ng-container *ngIf="activatedRoute.children.length === 0">
    <div class="flex flex-row">
      <div class="flex flex-row justify-center sm:container-default mb-[75px] w-full">
        <taxonomynode [taxonomynode]="taxonomyNode"
                      fromdate="{{dateNextWeek}}"
                      todate="{{dateNextWeek}}"
                      [isfullscreen]="true"
                      class="md:px-5 w-full">
          <ng-template #product let-product>
            <productcard (addOrderline)="addOrderline($event, product, null)"
                         (quantityChange)="changeProductSelectionQty(null,$event, product)"
                         (showProduct)="toggleDetailsdrawer($event, null)"
                         [product]="product"
                         [orderlines]="orderlines?.value"
                         class="w-full">
            </productcard>
          </ng-template>
          <ng-template #box let-box>
            <boxcard (addOrderline)="addOrderline($event, null, box)"
                     (quantityChange)="changeProductSelectionQty(null,$event, null, box)"
                     (personsChange)="changeBoxPersonQty(null,$event, box)"
                     (showBox)="toggleDetailsdrawer(null, $event)"
                     [box]="box"
                     [orderlines]="orderlines?.value"
                     class="w-full">
            </boxcard>
          </ng-template>
        </taxonomynode>
      </div>
      <div class="sidedrawer hidden md:block  p-5 mx-3 self-start md:sticky  md:top-[137px]">
        <ng-container *ngTemplateOutlet="sidedrawercontent"></ng-container>
      </div>
    </div>
    <div class="fixed bottom-0 left-0 right-0 z-10 md:w-[500px] md:my-[15px] md:mx-auto md:rounded-lg">
      <div class="flex flex-row mb-5 justify-center">
        <button (click)="toggleDrawer()" class="flex md:hidden button-light rounded-full">
          <div class="flex flex-wrap justify-center">
            <small class="lh-sm mt-auto mb-auto" *ngIf="order.get('calculatedPrice').value">({{order.get('calculatedPrice').value.priceincvat |currency: 'EUR':'symbol':'.2-2':'nl'}})</small>
            <span class="ms-2">{{'order.view_order' | translate }}</span>
          </div>
        </button>
      </div>
    </div>
  </ng-container>
  <router-outlet></router-outlet>

</div>

<!-- DETAILSDRAWER -->
<ng-template #detailsdrawer>

  <button type="button" class="flex items-center justify-center absolute h-9 w-9 top-[25px] right-[35px]" aria-label="Close" (click)="toggleDetailsdrawer()">
    <i class="bi bi-x-circle text-lg cursor-pointer"></i>
  </button>

  <div class="bg-white px-[25px] py-[75px] md:pt-[75px]">
    <ng-container *ngIf="shownProduct">
      <app-product [product]="shownProduct"
                   (addOrderline)="addOrderline($event, shownProduct, null)"
                   (quantityChange)="changeProductSelectionQty(null,$event, shownProduct)"
                   [orderlines]="orderlines?.value"
                   class="w-full">
      </app-product>
    </ng-container>
    <ng-container *ngIf="shownBox">
      <app-box [box]="shownBox"
               (addOrderline)="addOrderline($event, null, shownBox)"
               (quantityChange)="changeProductSelectionQty(null,$event, null, shownBox)"
               (personsChange)="changeBoxPersonQty(null,$event, shownBox)"
               [orderlines]="orderlines?.value"
               class="w-full">
      </app-box>
    </ng-container>
  </div>
</ng-template>

<ng-template #sidedrawercontent>
  <div class="flex flex-col bg-e-white p-5 rounded-lg overflow-scroll md:overflow-hidden" [formGroup]="order">
    <div class="flex flex-row justify-end md:hidden">
      <i class="bi bi-x-circle text-lg cursor-pointer" (click)="toggleDrawer()"></i>
    </div>
    <h3 class="mb-1">{{'singleorder.your_order' | translate}}</h3>
    <strong>{{'product.products' | translate}}</strong>
    <div *ngIf="orderlines?.length === 0">
      <span class="align-self-center d-flex px-0">
        {{'order.no_products_added' | translate}}
      </span>
    </div>
    <ng-container formArrayName="orderlines">
      <ng-container *ngFor="let line of orderlines?.controls; let i = index">
        <div id="productselection-{{i}}" [formGroupName]="i" [ngClass]="{'border-bottom': i < orderlines.value?.length -1 }" class="orderline row gx-1 py-1">
          <ng-container *ngIf="line.get('couponcodeitem').value">
            <productline *ngIf="line.value.sku"
                         [product]="line.value.object"
                         [orderline]="line.value"
                         [showPrice]="true">
            </productline>
            <boxline *ngIf="line.value.boxid"
                     [box]="line.value.object"
                     [orderline]="line.value"
                     [showPrice]="true">
            </boxline>
            <emptyline *ngIf="!line.value.sku && !line.value.boxid"
                       [orderline]="line.value"
                       [showPrice]="true">
            </emptyline>
          </ng-container>
          <ng-container *ngIf="!line.get('couponcodeitem').value">
            <productline *ngIf="line.value.sku"
                         [product]="line.value.object"
                         (quantityChange)="changeProductSelectionQty(null,$event, line.value.object)"
                         [orderline]="line.value"
                         [showPrice]="true">
            </productline>
            <boxline *ngIf="line.value.boxid"
                     [box]="line.value.object"
                     (quantityChange)="changeProductSelectionQty(null,$event, null, line.value.object)"
                     (personsChange)="changeBoxPersonQty(i,$event,  line.value.object)"
                     [orderline]="line.value"
                     [showPrice]="true">
            </boxline>
            <emptyline *ngIf="!line.value.sku && !line.value.boxid"
                       [orderline]="line.value"
                       [showPrice]="true">
            </emptyline>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>


    <pricetotal class="mt-[25px]"
                [calculatedPrice]="order.value?.calculatedPrice"
                [showShipping]="false"
                [showMov]="orderRecipeCount$.value < _env.singleorderNoMOVRecipecount"
                [countrycode]="countrycode">
    </pricetotal>

    <div class="flex flex-row mt-[25px] md:sticky bottom-0">
      <button class="button button-base rounded-full" [routerLink]="['checkout']">{{'singleorder.goto_checkout' | translate}}</button>
    </div>
  </div>
</ng-template>
