<div class="flex flex-row justify-between  whitespace-nowrap overflow-x-auto gap-4" (click)="showAbsoluteValues = !showAbsoluteValues">
  <ng-container *ngFor="let score of vitascores"> <!-- added grid-cols-5 to have it in the environment -->
    <div class="">
      <img class="mx-auto mt-2.5 h-10" height="40px" [alt]="score.alt" [src]="score.src" loading="lazy"/>
      <div class="flex flex-col justify-center items-center my-[15px]">
        <span class="text-sm"><span *ngIf="score.alt.includes('co2')">CO<sub>2</sub></span>{{ score.label | translate}}</span>
        <span class="text-sm whitespace-nowrap" *ngIf="score?.score?.scoreimprovementpercentage">
          <ng-container *ngIf="score.score.scoreimprovementpercentage > 0">+</ng-container>
          {{ (score?.score?.scoreimprovementpercentage || 0) | percent: '1.0-0'}}
        </span>
        <span class="text-xs mt-1 text-center opacity-75 " *ngIf="showAbsoluteValues" @collapse>
          <ng-container *ngIf="score.score.scoreimprovementpercentage > 0 && score.score.scoreimprovement > 0">+</ng-container>
          <ng-container *ngIf="score.unit !== 'CURRENCY'">
            {{ (score.score.scoreimprovement || 0) | number: '1.0-1'}} {{ score.unitPrefix + score.unit |translate}}
          </ng-container>
          <ng-container *ngIf="score.unit === 'CURRENCY'">
            {{ (score.score.scoreimprovement || 0) |currency: 'EUR':'symbol':'.2-2':'nl'}}
          </ng-container>
        </span>
      </div>
    </div>    
  </ng-container>
</div>
<div class="flex flex-row justify-between">
  <small class="mb-2 underline"><a routerLink="/{{'score.learn_more_url' | translate}}">{{'score.learn_more' | translate}}</a></small>
  <small class="mt-1 text-xs">{{'score.note' |translate}}</small>
</div>
