<div class="bg-e-white">
  <div class="md:py-[50px] rounded-xl">
    <div class="container-default flex flex-col-reverse md:flex-row md:justify-between px-[25px]">
      <div class="flex flex-col items-start justify-center md:w-1/3 md:mr-[25px]">
        <h1 class="" itemprop="name">{{_boxpage?.title}}</h1>

        <div *ngIf="!isOnServer" class="my-5" [innerHTML]="_boxpage?.shortdescription | safeHtml"></div>

        <div class="flex w-full justify-between mt-[25px]">
          <span class="my-auto me-2">{{'common.person' | translate}}</span>
          <div class="flex justify-between text-nowrap bg-white rounded-full w-2/3">
            <button class=" p-3 " [disabled]="persons.value === 1" (click)="changePersonQty(-1)" id="minuspersonqty">
              <i class="bi bi-dash-lg"></i>
            </button>
            <span class="text-center my-auto align-top quantity">{{box?.doublepersons? persons.value * 2 : persons.value}}</span>
            <button class=" p-3" [disabled]="persons.value === 4" (click)="changePersonQty(1)" id="pluspersonqty">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
        </div>
        <div class="flex w-full justify-between mt-[25px]">
          <span class="my-auto me-2">{{'boxpage.quantity_boxes' | translate}}</span>
          <div class="flex justify-between text-nowrap bg-white rounded-full w-2/3">
            <button class=" p-3 " [disabled]="quantity.value === 1" (click)="changeQuantity(-1)" id="minusqty">
              <i class="bi bi-dash-lg"></i>
            </button>
            <span class="text-center my-auto align-top quantity">{{quantity.value}}</span>
            <button class=" p-3" (click)="changeQuantity(1)" id="plusqty">
              <i class="bi bi-plus-lg"></i>
            </button>
          </div>
        </div>
        @if (singleboxsellable()) {
        <button (click)="chooseBox()" class="button-base rounded-full mt-[25px]" id="choosebox">
          <span>{{'singleorder.add_to_cart' | translate }}</span>
        </button>
        } @else if(box) {

        <div class="bg-e-gold rounded text-center text-white px-2 py-3 mt-[25px]">
          {{'boxpage.box_sellable_in_period' | translate }}
          <span class="d-block">
            <ng-container *ngIf="box?.singleboxsellablefrom"> {{'common.from' | translate | lowercase }} <strong>{{box.singleboxsellablefrom | date: 'dd MMMM
                yyyy'}}</strong></ng-container>
            <ng-container *ngIf="box?.singleboxsellableto"> {{'common.to' | translate | lowercase}} <strong>{{box.singleboxsellableto | date: 'dd MMMM
                yyyy'}}</strong></ng-container>
          </span>
        </div>
        }
      </div>

      <div class="md:w-2/3">
        <app-image size="lg" [imageobject]="imageRecipes[0]" class="rounded-3xl object-cover w-full"></app-image>
      </div>
    </div>
  </div>
  <div class="container-default flex flex-col px-[25px]">

    <div *ngIf="!isOnServer" class="my-5" [innerHTML]="_boxpage?.description | safeHtml"></div>

    <h2 class="mt-[25px]">{{'box.content' | translate}}</h2>
    <app-loader *ngIf="loading"></app-loader>
    <div class="flex mt-[25px]">
      <ng-container *ngIf="box?.boxPeriods[0].boxProducts.length>0">
        <app-chip *ngFor="let i of _env.subscriptionPersonChoices" [clickable]="true" (click)="personquantity = i" [selected]="personquantity === i"><span>{{i}}
            {{'common.person_abbr' | translate}}</span></app-chip>
      </ng-container>
    </div>
    <div class="mt-[25px]">
      <ul class="" *ngIf="box?.boxPeriods && box?.boxPeriods.length>0">
        <ng-container *ngFor="let product of box?.boxPeriods[0].boxProducts">
          <li class="py-1">
            <span>{{getIngredientQuantity(product)}} {{'unit_abbr.' + product.unit | translate | lowercase}} {{product.displayname}}</span>
            <small class="d-block" *ngIf="product.producer"><i>{{'common.from_posessive'| translate}} {{product.producer}}</i></small>
          </li>
        </ng-container>
      </ul>
    </div>
    <recipe-list class="mt-[25px] w-full" [recipes]="recipes" [loading]="recipesloading" [allergens]="allergensCtrl.value">
      <ng-template #recipe let-recipe>
        <recipecard [recipe]="recipe" [status]="'PROCESSED'" [selectedallergens]="allergensCtrl.value" (showRecipe)="showRecipe($event)">
        </recipecard>
      </ng-template>

    </recipe-list>
    <div class="mt-[25px]" *ngIf="_boxpage?.boxpagefaqs">
      <ng-container *ngFor="let faq of _boxpage?.boxpagefaqs">
        <faq [faqid]="faq.faqid" colorscheme="contrast"></faq>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #recipeTmpl>

  <div class="flex flex-col w-fit overflow-auto bg-white p-3">
    <div class="flex justify-end">
      <button (click)="offcanvasService.dismiss();"><i class="bi bi-x-lg"></i></button>
    </div>
    <div class="flex justify-center h-full">
      <app-recipe class="mb-5" [personquantity]="persons.value" [recipe]="shownRecipe"></app-recipe>
    </div>
  </div>

</ng-template>