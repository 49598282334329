import { Component, Input, OnInit, HostListener, TemplateRef, ViewChild, OnDestroy, Inject } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Lifestyle, Nutrient, Recipe, RecipeIngredient, RecipescoreType } from '../../_model/recipe';
import { LifestyleService } from '../../_service/LifestyleService';
import { BottomdrawerdialogData } from '../../_model/shared';
import { AuthService } from 'src/_service/AuthService';
import { SubscriptionService } from 'src/_service/SubscriptionService';
import { BottomdrawerDialogComponent } from '../bottomdrawerDialog/bottomdrawerDialog.component';
import { environment } from '../../_environments/environment';
import { Router, RouterLink } from '@angular/router';
import { GlobalService } from '../../_service/GlobalService';
import { MediaMatcher } from '@angular/cdk/layout';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { CapitalizeFirstPipe } from '../pipe/capitalizefirst.pipe';
import { VitascoresComponent } from '../vitascores/vitascores.component';
import { ImageComponent } from '../image/image.component';
import { ChipComponent } from '../chip/chip.component';
import { CustomerratingComponent } from '../customerrating/customerrating.component';
import { NgIf, NgFor, NgClass, LowerCasePipe } from '@angular/common';
import { WINDOW } from '../../_service/WindowService';
import { WebshopComponent } from '../../singleorderModule/webshop/webshop.component';
import { CmsPopupComponent } from '../cms-popup/cms-popup.component';
import { CmsService } from '../../_service/CmsService';

@Component({
  selector: 'app-recipe', // render recipe in any type of context
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss'],
  standalone: true,
  imports: [
    NgIf, NgFor, NgClass,
    CustomerratingComponent, ChipComponent, ImageComponent, VitascoresComponent, WebshopComponent, RouterLink,
    TranslateModule,
    CmsPopupComponent,
    CapitalizeFirstPipe, LowerCasePipe
  ]

})
export class RecipeComponent implements OnInit, OnDestroy {

  _env = environment;

  @ViewChild('bottomdrawerTemplateRef', { read: TemplateRef }) bottomdrawerTemplate: TemplateRef<any>;
  scrollStrategy: ScrollStrategy;
  bottomsheetRef: MatBottomSheetRef;
  bottomSheetOpened = false;

  _recipe: Recipe
  @Input() set recipe(value: Recipe) {
    this._recipe = value;
    if (value)
      this.initRecipeData(value);
  } // the recipe data is passed in as is to the component

  lifestyles: Lifestyle[];
  private _persons: number = 2;
  @Input() set personquantity(value: number) {
    if(!value) return;
    value = Number(value)
    if (this._env.subscriptionPersonChoices.includes(value)){
      this._persons = value
    }
  }
  get personquantity() { return this._persons }

  @Input() popup = false;

  donePreparations: number[] = [];
  vitascores = [];
  isMobile;

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    this.resize();
  }

  constructor(
    public _lifestyleService: LifestyleService,
    private _globalService: GlobalService,
    private _authService: AuthService,
    private _subscriptionService: SubscriptionService,
    private _sso: ScrollStrategyOptions,
    private _bottomSheet: MatBottomSheet,
    public router: Router,
    private media: MediaMatcher,
    @Inject(WINDOW) private window: Window,
  ) {
    //console.warn('enter app-recipe');
  }

  ngOnInit() {

    this.scrollStrategy = this._sso.noop();

    this.isMobile = this.media.matchMedia('(max-width: 960px)').matches;
    // dont show the bottomsheet if a recipe is in a popup, otherwise it will close the layover
    if (!this.popup) {
      setTimeout(() => {
        if (this.popup) //added extra check for if the boolean is changed in the 100 ms
          this.openBottomsheet();
      }, 100);
    }
  }

  ngOnDestroy() {
    if (!!this.bottomsheetRef) {
      this.bottomsheetRef.dismiss();
    }
  }

  resize() {
    this.isMobile = this.media.matchMedia('(max-width: 960px)').matches;
  }
  scrollToMain() {
    document.getElementById('main').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  openBottomsheet() {
    if (this._authService.isAuthenticated()) { return; }
    //this has to be implemented, only the personalmenu flow isnt ready yet
    if (this._subscriptionService.hasSubscriptions) { return; }

    if (!this.bottomSheetOpened) {
      this.bottomsheetRef = this._bottomSheet.open(BottomdrawerDialogComponent, {
        data: new BottomdrawerdialogData(this.bottomdrawerTemplate),
        hasBackdrop: false,
        disableClose: true, closeOnNavigation: false,
        scrollStrategy: this.scrollStrategy
      });
      this.bottomSheetOpened = true;

      this.bottomsheetRef.afterOpened().subscribe(() => {
        this.bottomSheetOpened = true;
      });
      this.bottomsheetRef.afterDismissed().subscribe(() => {
        this.bottomSheetOpened = false;
      });

    }
  }
  closeBottomsheet() {
    this.bottomsheetRef.dismiss();
  }

  initRecipeData(recipe: Recipe) {
    // workaround because sometimes we think that 
    if (!!recipe['name']) { return }

    //has a recipeurl if is clicked through by a recipecard, so it can register a pageview, then it is opened in a overlay in stead of a own page.
    if (recipe['recipeurl']) {
      try {
        // these calls will fail in non-browser versions, but we should not care about this there anyway
        const page_view = { // there is a lot of different pageview event formats partly documentent on the web,
          // GTM sends in history change events that could be used to measure pageviews also
          event: 'virtualPageview', // or  "Pageview" ??
          lang: this._globalService.getLanguage(),
          pageUrl: recipe['recipeurl'],
          pageTitle: recipe['recipeurl'] // page title is not  knowns here ...
        };
        this.window['dataLayer'].push(page_view);
      } catch (e) {
        // console.warn('datalayer access failed:');
        // console.warn(e);
      }
    }

    recipe.ingredients = recipe.ingredients.sort((a, b) => a.sequenceid - b.sequenceid);
    recipe.preparations = recipe.preparations.sort((a, b) => a.sequenceid - b.sequenceid);
    const nutrient_order = Object.keys(Nutrient); // we want the order as defined in the enum
    recipe.nutrients = recipe.nutrients.sort((a, b) => nutrient_order.indexOf(a.name) - nutrient_order.indexOf(b.name));
    this._recipe = recipe;

    // get livestyles for recipe
    this._lifestyleService.getLifestyleList().subscribe(
      result => {
        this.lifestyles = result;
        // filters non available lifestyles
        if (this._recipe) {
          this._recipe.lifestyles = this._recipe.lifestyles.filter(l => this.lifestyles.map(s => s.id).includes(l.lifestyleid));
        }
      }
    );

  };

  getCalories(recipe: Recipe) {
    if (!recipe || !recipe.nutrients) {
      return;
    }
    const calories = recipe.nutrients.filter(val => val.name === Nutrient.ENERGY)[0];
    if (calories) {
      return calories.quantity;
    } else {
      return null;
    }
  }

  getIngredientQuantity(ingredient: RecipeIngredient) {
    let qty;
    switch (this.personquantity) {
      case 1:
        qty = ingredient.onepersquantity;
        break;
      case 2:
        qty = ingredient.twopersquantity;
        break;
      case 3:
        qty = ingredient.threepersquantity;
        break;
      case 4:
        qty = ingredient.fourpersquantity;
        break;
      default:
        qty = ingredient.twopersquantity;
        break;
    }
    return qty > 0 ? qty : '';
  }

  toggleDonePreparation(sequenceid: number) {
    console.log(sequenceid)
    if (!this.donePreparations) {
      return;
    }
    const index = this.donePreparations.indexOf(sequenceid);
    if (index >= 0) {
      this.donePreparations.splice(index, 1);
    } else {
      this.donePreparations.push(sequenceid);
    }
  }

  getLifestyle(lifestyleid: number): Lifestyle {
    if (!this._recipe) {
      return;
    }

    if (!!this.lifestyles) {
      const lifestyle = this.lifestyles.filter(val => val.id === lifestyleid)[0];

      if (lifestyle) {
        return lifestyle;
      } else {
        return null;
      }
    }

  }

  getScore(scoretype: string) {
    // debugger;
    const type = RecipescoreType[scoretype];
    if (!this._recipe || !this._recipe.recipeScores) {
      return { scorerating: '', scorevalue: '0' };
    }

    const res = this._recipe.recipeScores.filter(r => r && r.scoretype == type)[0];
    if (res) {
      return res;
    } else {
      return { scorerating: '0', scorevalue: '0' };
    }
  }

  getRecipecardUrl(): string {
    if (!this._recipe || !this._recipe.recipecards) return null;

    const lang = this._globalService.activeLanguage.language;

    const card = this._recipe.recipecards.find(f => f.language === lang);
    if (!card || !card.recipecardfullurl) return null;
    return this._env.assetserver + card.recipecardfullurl;
  }

}
