import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Product } from '../../../_model/product';
import { Nutrient } from '../../../_model/recipe';
import { GlobalService } from '../../../_service/GlobalService';
import { PricingService } from '../../../_service/PricingService';
import { BaseorderlineComponent } from '../baseorderline.component';
import { LocalizedDatePipe } from '../../pipe/localizedDate.pipe';
import { ChipComponent } from '../../chip/chip.component';
import { ImageComponent } from '../../image/image.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToggleComponent } from '../../toggle/toggle.component';
import { PricetagComponent } from '../../pricetag/pricetag.component';
import { NgIf, NgFor, LowerCasePipe, CurrencyPipe } from '@angular/common';
import { QuantityselectionComponent } from '../../quantityselection/quantityselection.component';
import { SafeHtmlPipe } from 'src/sharedModule/pipe/safe-html.pipe';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor,
    PricetagComponent, ToggleComponent, ImageComponent, ChipComponent, QuantityselectionComponent,
    ReactiveFormsModule, TranslateModule,
    LowerCasePipe, CurrencyPipe, LocalizedDatePipe, SafeHtmlPipe
  ]
})
export class ProductComponent extends BaseorderlineComponent implements OnInit, OnDestroy {

  @Input() class: string;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService,
    public _pricingService: PricingService,
    public _globalService: GlobalService,
  ) {
    super(route, router, _translate, _pricingService, _globalService);
  }

  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getCalories(product: Product) {
    if (!product) {
      return;
    }
    const calories = product.nutrients.filter(val => val.name === Nutrient.ENERGY)[0];
    if (calories) {
      return calories.quantity;
    } else {
      return null;
    }
  }


}
