<div class="placeholder h-full w-full bg-white rounded-lg">
    <div class="animate-pulse h-full w-full">

        @switch(preset){
        @case('RECIPE'){
        <div class="flex flex-col justify-end h-full p-3 gap-3">
            <div class="h-48"></div>
            <div class="flex flex-row justify-between mb-5">
                <div class="h-10 w-4/5 bg-slate-200 rounded-md"></div>
                <div class="h-10 w-10 rounded-full bg-slate-200"></div>
            </div>
            <!-- <div class=" w-1/2 bg-slate-200 rounded-md"></div> -->
            <div class="flex flex-row gap-3 h-9">
                <div class="h-full w-full rounded-full bg-slate-200"></div>
                <div class="h-full  w-full rounded-full bg-slate-200"></div>
                <div class="h-full  w-full rounded-full bg-slate-200"></div>
            </div>
            <div class="h-6 w-full bg-slate-200 rounded-md"></div>
            <div class="h-6 w-full bg-slate-200 rounded-md"></div>
            <div class="h-12 w-full my-1 bg-slate-200 rounded-md"></div>
        </div>
        }@case('RECIPE_CONDENSED'){
        <div class="flex flex-col justify-end h-full p-3 gap-3">
            <div class="flex flex-row justify-between">
                <div class="h-12 w-4/5 bg-slate-200 rounded-md"></div>
                <div class="h-10 w-10 rounded-full bg-slate-200"></div>
            </div>
            <div class="h-6 w-1/2 bg-slate-200 rounded-md"></div>
        </div>
        }@default{
        <ng-content></ng-content>
        }
        }



    </div>
</div>