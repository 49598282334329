<div class="flex rounded-lg" [ngClass]="layout === 'CONDENSED' ? 'flex-col bg-white' : 'flex-row'">  
  <div [ngClass]="layout === 'CONDENSED' ? 'w-full' : 'w-1/3 max-w-[125px] mr-2.5'">
    <app-image [alt]="_line.recipe?.displayname" size="md" [src]="_line.recipe?.websitemediumimageurl" class="rounded-md"></app-image>

  </div>
  <div class="flex flex-col" [ngClass]="layout === 'CONDENSED' ? 'px-2.5 py-[15px] basis-[130px] shrink-0': 'w-2/3 grow'">
    <div class="flex flex-row justify-between">
      <div class="mr-2.5">
        <small >
          <!-- <img *ngIf="_line.recipe?.salessurcharge < 0" class="h-[1.4em] float-left me-1 mt-1" src="/assets/images/icons/icon_green_deal_badge_v2.svg " /> -->
          <strong class="text-e-dark-green">
            {{_line.recipe?.displayname}}
          </strong>
        </small>
        <small *ngIf="layout !== 'ORDERFLOW'" class="line-clamp-2 text-e-dark-green flex">{{_line.recipe?.subtitle}}</small>
      </div>
      <button class="min-w-4 min-h-5 mt-2 self-start" (click)="removeRecipeline()" *ngIf="removable">
        <img *ngIf="layout === 'NORMAL'" src="./assets/images/icons/delete.svg" height="20px" width="16px" alt="delete" />
        <i *ngIf="layout === 'ORDERFLOW'" class="bi bi-x-circle"></i>
      </button>
    </div>

    <ng-container [ngSwitch]="layout">
      <div *ngSwitchCase="'NORMAL'" class="mt-[15px] flex flex-row items-end justify-between">
        <app-personselection class="max-w-[175px] w-full md:w-3/5" [contrastColorscheme]="colorscheme" small [persons]="_line.persons" [editable]="editable" (personsChange)="changePersonQty($event)">
          <span>{{'common.person_abbr'|translate}}</span>
        </app-personselection>
        <pricetag *ngIf="removable" [calculatedPrice]="_line?.calculatedPrice" [couponcodeItem]="_line?.couponcodeitem" [compensationItem]="_line?.compensationitem"></pricetag>
      </div>
      <div *ngSwitchCase="'CONDENSED'" class="col-6 text-nowrap px-0">
        <small>{{_line.doublepersons? _line.persons * 2 : _line.persons}} {{'common.person_abbr'|translate}}</small>
      </div>
      <div *ngSwitchCase="'ORDERFLOW'" class="mt-[15px] flex flex-row flex-wrap items-end justify-between">
        <div class="flex flex-row gap-1">
          <pricetag [calculatedPrice]="_line?.calculatedPrice" [couponcodeItem]="_line?.couponcodeitem" [compensationItem]="_line?.compensationitem"></pricetag>
          <small class="self-end text-gray-400">{{_line?.calculatedPrice?.priceincvat / (_line.doublepersons ? _line.persons * 2 : _line.persons) | currency : 'EUR'}} /{{'common.person_abbr'|translate}}</small>
        </div>
        <app-personselection class="max-w-[175px] w-full md:w-2/5" [contrastColorscheme]="colorscheme" small [persons]="_line.persons" (personsChange)="changePersonQty($event)" [editable]="editable">
          <span>{{'common.person_abbr'|translate}}</span>
        </app-personselection>
      </div>
    </ng-container>
  </div>
</div>
