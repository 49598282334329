<div class="flex h-full max-h-[450px]">
  <div *ngIf="_product?.discountprice && qtyeditable" class="discountprice rounded-lg px-1 m-2 bg-e-gold text-white absolute">
    <small class="text-dark" *ngIf="_product?.discountpercentage; else discountprice">
      {{calculatedPrice?.calculateddiscountpercentage}}% {{'common.discount' | translate}}
    </small>
    <ng-template #discountprice>
      <small class="text-dark">
        {{calculatedPrice?.discountincvat |currency: 'EUR':'symbol':'.2-2':'nl'}} {{'common.discount' | translate}}
      </small>
    </ng-template>
  </div>

  <div class="flex flex-col rounded-lg bg-white justify-between  w-full p-[15px] md:p-[25px]">
    <div class="flex justify-center items-center h-[170px]">
      <app-image (click)="showProductAction()" size="md" *ngIf="!disableShowAction; else imagenavigation" [imageobject]="_product" alt="{{_product?.title}}"
        class="flex flex-row justify-center object-cover cursor-pointer"></app-image>
      <ng-template #imagenavigation>
        <a [routerLink]="[_product['producturl']]">
          <app-image [imageobject]="_product" size="md" alt="{{_product?.title}}" class="flex flex-row justify-center object-cover"></app-image>
        </a>
      </ng-template>
    </div>
    <div>
      <h3 (click)="showProductAction()" *ngIf="!disableShowAction; else linknavigation" class="text-base md:text-xl font-sans cursor-pointer">
        <!-- makes sure that a translated version is shown if a box or product is set with the orderdescription as fallback -->
        <ng-container *ngIf="_product; else orderdescription">{{_product?.title || _product?.name}}</ng-container>
        <ng-template #orderdescription>{{_orderline?.description}}</ng-template>
      </h3>
      <ng-template #linknavigation>
        <h3 [routerLink]="[_product['producturl']]" class="text-base md:text-xl font-sans">
          <!-- makes sure that a translated version is shown if a box or product is set with the orderdescription as fallback -->
          <ng-container *ngIf="_product; else orderdescription">{{_product?.title || _product?.name}}</ng-container>
          <ng-template #orderdescription>{{_orderline?.description}}</ng-template>
        </h3>
      </ng-template>
      <small>{{_product?.shopquantity}} {{'unit.' + _product?.shopunit | translate }}</small>
    </div>
    <div class="flex flex-row" *ngIf="qtyeditable || repeatableeditable">
      <pricetag class="font-bold text-base mt-2.5" [productprice]="true" [calculatedPrice]="calculatedPrice"></pricetag>
    </div>

    <div class="flex flex-row items-center mt-[15px]" *ngIf="qtyeditable">
      <strong class="capitalize hidden md:block">{{'common.pieces' |translate }}</strong>
      <app-quantityselection class=" w-full md:ml-[15px]" [quantity]="quantity.value" (quantityChange)="setQuantity($event)"
        [contrastColorscheme]="colorscheme"></app-quantityselection>
    </div>

    <span *ngIf="!qtyeditable">{{'common.piece' |translate : {count: quantity.value} }}</span>
    <app-toggle *ngIf="repeatableeditable" [formControl]="repeatable">{{'subscription.product_weekly' | translate}}</app-toggle>
    <!--<div class="h-[45px]" *ngIf="!repeatableeditable"></div>-->
  </div>
</div>