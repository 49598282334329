import { isPlatformBrowser, isPlatformServer, NgIf } from '@angular/common';
import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Nutrient, Recipe } from 'src/_model/recipe';
import { environment } from '../../_environments/environment';
import { Box } from '../../_model/box';
import { AggregatedCustomerRating } from '../../_model/customerrating';
import { Flowtype, Orderline } from '../../_model/order';
import { Product } from '../../_model/product';
import { Entitytype, Redirect } from '../../_model/redirect';
import { Language, Sourcetype } from '../../_model/shared';
import { CmsService } from '../../_service/CmsService'; // TODO: rename to general CMS service ?
import { GlobalService } from '../../_service/GlobalService';
import { LinkService, SeoSocialShareData } from '../../_service/LinkService';
import { OrderService } from '../../_service/OrderService';
import { RedirectService } from '../../_service/RedirectService';
import { StaticpageService } from '../../_service/StaticpageService';
import { WINDOW } from '../../_service/WindowService';
import { fadeIn } from '../../_theme/animations';
import { BoxpageComponent } from '../../sharedModule/boxpage/boxpage.component';
import { FooterComponent } from '../../sharedModule/footer/footer.component';
import { LifestyleComponent } from '../../sharedModule/lifestyle/lifestyle.component';
import { LoaderComponent } from '../../sharedModule/loader/loader.component';
import { BoxComponent } from '../../sharedModule/orderline/box/box.component';
import { ProductComponent } from '../../sharedModule/orderline/product/product.component';
import { RecipeComponent } from '../../sharedModule/recipe/recipe.component';
import { RecipecardComponent } from '../../sharedModule/recipecard/recipecard.component';
import { RecipelistComponent } from '../../sharedModule/recipelist/recipelist.component';
import { CMSpageComponent } from '../cmspage/cmspage.component';
import { SafeHtmlPipe } from 'src/sharedModule/pipe/safe-html.pipe';
import { ModalSize, Popup, PopupAudience, PopupCloseConfig } from 'src/_model/storyblock';
import { CmsPopupComponent } from 'src/sharedModule/cms-popup/cms-popup.component';
import { PlaceholderComponent } from 'src/sharedModule/placeholder/placeholder.component';

const SHORT_URL = /[0-9a-fA-F]{6}/; // 6 digit hex code
const REMOTE_404 = { slug: '/pagina-niet-gevonden-404', seourl: '/page/404-pagina-niet-gevonden' };
@Component({
  selector: 'app-staticpage',
  templateUrl: './staticpage.component.html',
  styleUrls: ['./staticpage.component.scss'],
  animations: [fadeIn],
  standalone: true,
  //host: { ngSkipHydration: 'true' },
  imports: [
    NgIf,
    SafeHtmlPipe,
     TranslateModule,
    LoaderComponent, CMSpageComponent, ProductComponent, RecipeComponent, RecipelistComponent, 
    RecipecardComponent, LifestyleComponent, BoxpageComponent, BoxComponent, 
    FooterComponent, CmsPopupComponent, PlaceholderComponent
  ]
})
export class StaticpageComponent implements OnInit, OnDestroy {
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (this.window.scrollY > 250) {
      this.showScrollTop = true;
    } else {
      this.showScrollTop = false;
    }
  }

  environment = environment;
  is404 = false;
  ishome = false;
  loading = true;
  interval: any;
  path: string;
  entity_type: Entitytype;
  entity_lang: string;
  entity: any; // depends on the entity_type
  showScrollTop = false;

  //date for the comparable recipes to display
  datenextweek = this._orderService.assumedDeliverydate;
  orderlines: FormArray;
  order: FormGroup;

  staticpageContent: string;

  // META data
  title: string;
  description: string;
  image_src: string;
  image_thumb: string;
  baseUrl = this._linkService.base_url;
  isPrerendered = false;

  recipeModalstory_slug = 'general/recipedetails-popup'
  recipeModalContent = new Popup(null, null, null, ModalSize.MEDIUM, "e-white", null, PopupCloseConfig.ALL, PopupAudience.NON_CUSTOMERS);
  persons: number = null;

  constructor(
    public route: ActivatedRoute,
    public _translate: TranslateService,
    private _staticpageService: StaticpageService,
    private _storyblokService: CmsService,
    private _redirectService: RedirectService,
    private _globalService: GlobalService,
    private _orderService: OrderService,
    private _linkService: LinkService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
  ) {

    this.isPrerendered = this._globalService.isPrerendered;

  }

  ngOnInit() {

    this.orderlines = this._orderService.orderlines;
    this.order = this._orderService.order;

    this.route.url.subscribe(segments => {
      this.removeAlternateLinks();
      this._linkService.removeMetaData();
      this.path = '/' + segments.map(s => s.path).join('/');
      // console.debug('start get info for static page with path:', this.path, '... ');
      if (segments.length === 3 && segments[1].path === 'id') { // technical urls <type>/id/<id>
        this.entity_type = this._redirectService.enttype[segments[0].path.toLowerCase()];
        this.entity_lang = this._translate.currentLang.toUpperCase(); // asume this is also the lang of the remote object
        this.loadRemoteData(segments[2].path);
      } else { // seo or short url
        let look_for;
        if (segments.length > 0) {
          const last_segement = segments.slice(-1)[0].path;
          look_for = SHORT_URL.test(last_segement) ? 'shorturl' : 'seourl';
        } else {
          look_for = 'seourl';
        }

        if (this.path === '/' || this.path === '') {
          this.path = '/home'
          this.ishome = true;
          if (isPlatformServer(this.platformId)) {
            return;
          }
        } else {
          this.ishome = false;
        }
        // StoryBlock CMS
        const isDraft = isPlatformBrowser(this.platformId) && this.window.location && (this.window.location.href.includes('localhost') || this.window.location.href.includes('tst.ecom') || this.window.location.href.includes('cms'));
        const version = isDraft ? 'draft' : 'published';

        this._storyblokService.getStory(this.path, { version: version, resolve_links: "1", language: this._globalService.getLanguage() }).then(data => {
          if (data.story) {
            this.entity_type = Entitytype.STORY;
            this.entity = data.story;
            this.initData(this.entity);
          } else {
            if (!!data.stories) {
              for (const story of data.stories) {
                if (story.name.toLowerCase() === 'home') {
                  this.entity_type = Entitytype.STORY;
                  this.initData(story);
                }
              }
            }

          }
          this.loading = false;
          // this.initData(story);
        }).catch(() => {

          // use the old APIs as a fallback for now
          //still needed for the recipes, products, e.a
          const search_params = {};
          search_params[look_for] = this.path;
          this._redirectService.getRedirectByUrl(search_params).subscribe(entity => {
            this.entity_type = entity.entitytype as Entitytype;
            this.entity_lang = entity.preferredlanguage;
            if (!!entity.preferredlanguage) {
              this._globalService.setLanguage(Sourcetype.URL, entity.preferredlanguage);
            };
            this.setAlternateLinks(entity);
            this.loadRemoteData(entity.entityid);
          }, url_not_found => {
            // console.error('Static page not found in redirectService for path: ', this.path);
            this.handle_404();
          });
        });
      }
    });
    this.route.queryParams.subscribe(params=>{
      this.persons =  params.persons? params.persons : null;
    })
  }
  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.removeAlternateLinks();
  }
  handle_404() {
    // console.debug('handle 404 ...');
    if (this.is404) {
      this.loading = false;
    } else {
      this.is404 = true;
      // TODO: we should allow for 404 per entity type so we can make in telligent choices about what we ant to show the user
      if (REMOTE_404) {
        if (REMOTE_404.slug) {
          this._storyblokService.getStory(REMOTE_404.slug, { version: 'draft', resolve_links: "1", language: this._globalService.getLanguage() }).then(data => {
            // console.debug(data);
            if (data.story) {
              this.entity_type = Entitytype.STORY;
              this.entity = data.story;
              this.initData(this.entity);
            }
            this.loading = false;
          });
        } else {
          this._redirectService.getRedirectByUrl(REMOTE_404).subscribe(entity => {
            this.entity_type = entity.entitytype as Entitytype;
            this.entity_lang = entity.preferredlanguage;
            if (!!entity.preferredlanguage) {
              this._globalService.setLanguage(Sourcetype.URL, entity.preferredlanguage);
            };
            this.loadRemoteData(entity.entityid);
          }, remote_404_not_found => {
            this.loading = false;
          });
        }
      }
    }
  }

  loadRemoteData(id) {
    // console.debug("start loading data for ", this.entity_type, ', id: ', id, "...");
    this._staticpageService.get(id, this.entity_type).subscribe(entity => {
      if (!entity) return this.handle_404();
      // console.debug('... success');
      this.initData(entity);
      if(this.entity_type === Entitytype.RECIPE)
          this.loadRecipeModalStory();

      this.loading = false;
    },
      object_not_found => {
        console.error('server error ', object_not_found);
        this.handle_404();
      });
  }

  loadRecipeModalStory(){
    this._storyblokService.getStory(this.recipeModalstory_slug, { resolve_links: "1", language: this.entity_lang }).then(data => {
      this.recipeModalContent._uid = data.story.content._uid
      this.recipeModalContent.sections = data.story.content.sections
    }).catch(error => {
      console.error(error)
    })
  }

  initData(entity) {
    // console.log(entity)
    this.extractMetaData(entity);
    this.entity = entity;
    try {
      this.setSeoData();
    } catch (seo_error) {
      console.warn(seo_error);
    }
    try {
      this.setStructuredData();
    } catch (str_error) {
      console.warn(str_error);
    }
    this._globalService.setTitle(this.title)
  }

  extractMetaData(entity) {
    switch (this.entity_type) {

      case Entitytype.STORY: // from storyblok
        // TODO: check if we can use entity.meta_data 

        // backup version of meta tags (obsolete)
        const meta_data = !!entity.content.meta[0] ? entity.content.meta[0] : { title: 'Ekomenu', description: 'Ekomenu', meta_image: { filename: '' } };
        if (!!meta_data) {
          this.title = !!entity.content.meta_tags?.title ? entity.content.meta_tags.title : meta_data.meta_title;
          this.description = !!entity.content.meta_tags?.description ? entity.content.meta_tags.description : meta_data.meta_description;
          this.image_src = !!entity.content.meta_tags?.og_image ? entity.content.meta_tags.og_image : meta_data.meta_image.filename;
        }
        if (isPlatformBrowser(this.platformId))
          this._linkService.addLinkTag({ rel: 'canonical', href: window.location.origin + window.location.pathname }, true)

        if (!!entity.translated_slugs) {
          for (const trans of entity.translated_slugs) {
            this._linkService.createLinkTag(trans.lang as Language, '/' + trans.lang.slice(0, 2) + '/' + trans.path, this.ishome, this.is404);
          }
        } else {
          this._linkService.createLinkTag(Language.NL, entity.full_slug, this.ishome, this.is404);
        }
        break;

      case Entitytype.STATICPAGE:
        let images = entity.content.match(/(<ngx-image.*?>)/gm);
        if (!images) {
          images = entity.content.match(/(<img.*?>)/gm);
        }

        if (!!images && images.length > 0)
          images = images.filter(f => !f.toLowerCase().includes('icon') && !f.toLowerCase().includes('.svg'));

        if (!!images && images.length > 0) {
          this.image_src = new RegExp(/src="(.*?)"/gm).exec(images[0])[1];
          try {
            this.image_thumb = new RegExp(/thumb="(.*?)"/gm).exec(images[0])[1];
          } catch (thumb_error) {
            this.image_thumb = null;
          }
        }
        this.title = entity.title;
        //regex first removes all html code, then removes returns and double spaces
        this.description = entity.content.replace(/<[^>]*>/g, '').replace(/\s\s+/g, ' ').trim();
        this.staticpageContent = `<style>${entity.stylescontent}</style>${entity.content}`;
        break;
      case Entitytype.LIFESTYLE:
        this.title = entity.seotitle ? entity.seotitle : entity.title ? entity.title : entity.subtitle;
        this.description = entity.seodescription ? entity.seodescription : entity.description;
        this.image_src = entity.websiteimageurl;
        break;
      case Entitytype.BOXPAGE:
        this.title = entity.seotitle ? entity.seotitle : entity.title ? entity.title : entity.subtitle;
        this.description = entity.seodescription ? entity.seodescription : entity.description;
        this.image_src = entity.websiteimageurl;
        break;
      case Entitytype.RECIPE:
        this.title = entity.displayname + ' ' + entity.subtitle;
        this.description = entity.intro;
        this.image_src = entity.websiteimageurl;
        this.image_thumb = entity.websitethumbimageurl;
        break;
      case Entitytype.PRODUCT:
        this.title = entity.name; // entity.title;
        this.description = entity.shortdescription;
        this.image_src = entity.websiteimageurl;
        this.image_thumb = entity.websitethumbimageurl;
        break;
      case Entitytype.BOX:
        // TODO
        break;
      case Entitytype.TAXONOMY:
        // TODO
        break;
      default:
        break;
    }
    // ONLY etna based images are served from asset server
    if (this.entity_type !== Entitytype.STATICPAGE) {
      if (this.image_src && !this.image_src.startsWith('http')) {
        this.image_src = this.environment.assetserver + this.image_src;
      }
      if (this.image_thumb && !this.image_thumb.startsWith('http')) {
        this.image_thumb = this.environment.assetserver + this.image_thumb;
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      if (this.window.location.href.includes('#')) {
        const splitUrl = this.window.location.href.split('#');
        this.jumpToAnchor(splitUrl[splitUrl.length - 1]);
      }
    }
  }

  jumpToAnchor(anchor: string) {
    let times = 0;
    this.interval = setInterval(() => {
      times++;
      try {
        const anchorSection = document.querySelector('#' + anchor);
        if (!!anchorSection) {
          anchorSection.scrollIntoView();
          clearInterval(this.interval);
        }
      } catch (e) {
        // die silently
      }

      if (times === 30) {
        console.log('clearing interval, no more jumpsto make');
        clearInterval(this.interval);
      }
    }, 20);
  }

  scrollToTop() {
    this.window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  setSeoData() {
    //it shouldnt prerender any tags on the homepage when not in browser
    if ((this.ishome || this.is404) && !isPlatformBrowser(this.platformId)) return;

    const seoData: SeoSocialShareData = {
      title: this.title ? this.title.slice(0, 70) : this.entity.title,
      description: this.description ? this.description.slice(0, 200) : '',
      url: this.baseUrl + this.path
    };

    if (this.image_src) {
      seoData.image = this.image_src;
    }
    this._linkService.setMetaData(seoData);
  }
  setStructuredData() {
    //it shouldnt prerender any tags on the homepage when not in browser
    if ((this.ishome || this.is404) && !isPlatformBrowser(this.platformId)) return;
    // extract image
    const image_obj = this.image_src ? this._linkService.getJsonLdObject('ImageObject', {
      url: this.image_src,
      contentUrl: this.image_src,
    }) : null;
    if (!!image_obj && this.image_thumb) {
      image_obj.thumbnail = this._linkService.getJsonLdObject('ImageObject', {
        contentUrl: this.image_thumb,
        url: this.image_thumb
      });
    }
    // all object have the samen author
    const author_obj = this._linkService.getJsonLdObject('Organisation', { name: 'Ekomenu' });

    // we may add the language
    const lang_obj = this._linkService.getJsonLdObject('Language', {
      name: this.entity_lang,
      alternateName: this.entity_lang
    });
    let base_obj = {
      url: this.path,
      author: author_obj
    };
    if (this.image_src)
      base_obj['image'] = image_obj

    base_obj = this.add_rating(this.entity.rating, base_obj);

    let jsonLdObject;
    switch (this.entity_type) {
      case 'RECIPE':
        jsonLdObject = this.prepare_recipe(this.entity, base_obj);
        break;
      case 'PRODUCT':
        jsonLdObject = this.prepare_product(this.entity, base_obj);

        break;
      default:
        // https://schema.org/WebPage
        jsonLdObject = this._linkService.getJsonLdObject('WebPage', {
          ...base_obj,
          mainContentOfPage: this.entity_type === 'STATICPAGE' ? this.entity.content.replace(/<script.*?<\/script>/gi, '') : this.description,
          isAccessibleForFree: true,
          inLanguage: lang_obj,
          name: this.title,
        });
        if (!!image_obj) {
          jsonLdObject.primaryImageOfPage = image_obj;
        }
        if (this.image_thumb) {
          jsonLdObject.thumbnailUrl = this.image_thumb;
        }
        break;
    }

    this._linkService.setJsonLdData(jsonLdObject);
  }
  setAlternateLinks(redirect: Redirect) {
    //it shouldnt prerender any tags on the homepage when not in browser
    if ((this.ishome || this.is404) && !isPlatformBrowser(this.platformId)) return;

    this._linkService.createLinkTag(redirect.preferredlanguage, redirect.seourl);

    const baseUrl = this._linkService.base_url;
    this._linkService.addLinkTag({ rel: 'canonical', href: baseUrl + redirect.seourl }, true)
    
    if (!redirect.languagealternatives) return;
    Object.keys(redirect.languagealternatives).forEach(k => {
      this._linkService.createLinkTag(Language[k], redirect.languagealternatives[k]);
    })
  }

  removeAlternateLinks() {
    this._linkService.removeLinkTag('rel="alternate"')
    this._linkService.removeLinkTag('rel="canonical"')
  }
  prepare_recipe(recipe: Recipe, base_obj) {
    // https://schema.org/Recipe
    const instructions = recipe.preparations.map(prep => {
      return this._linkService.getJsonLdObject('HowToStep', { position: prep.sequenceid, text: prep.description, numberOfItems: recipe.preparations.length });
    });

    const nutritionInformation = this._linkService.getJsonLdObject('NutritionInformation', {});
    recipe.nutrients.forEach(nut => {
      const unit = nut.name === 'ENERGY' ? this._translate.instant('SEOnutrient.' + nut.name) : this._translate.instant('unit.' + nut.unit);
      Object.assign(nutritionInformation, {
        [this._translate.instant('SEOnutrient.' + nut.name)]: nut.quantity + ' ' + unit
      });
    });

    const unsaturatedfat = Number(recipe.nutrients.filter(nut => nut.name === Nutrient.FAT)[0]?.quantity) - Number(recipe.nutrients.filter(nut => nut.name === Nutrient.SATURATEDFAT)[0]?.quantity);
    Object.assign(nutritionInformation, {
      [this._translate.instant('SEOnutrient.UNSATURATEDFAT')]: unsaturatedfat + ' ' + this._translate.instant('unit.GRAM')
    });



    const recipe_obj = this._linkService.getJsonLdObject('Recipe', {
      ...base_obj,
      name: String(recipe.displayname),
      description: String(recipe.intro),
      cookTime: 'PT' + Number(recipe.cookingtime) + 'M0S',
      prepTime: 'PT0M',
      totalTime: 'PT' + Number(recipe.cookingtime) + 'M0S',
      recipeIngredient: recipe.ingredients.map(ingr => {
        return ingr.twopersquantity + ' ' + this._translate.instant('unit.' + ingr.unit) + ' ' + ingr.displayname;
      }),
      recipeInstructions: instructions,
      nutrition: nutritionInformation,
      recipeYield: '2',
    });
    return recipe_obj;
  }
  prepare_product(product: Product, base_obj) {
    const brand = this._linkService.getJsonLdObject('Brand', { name: 'Ekomenu' });
    // const author = this._linkService.getJsonLdObject('Organization', {name: 'Ekomenu'});
    const offers = this._linkService.getJsonLdObject('Offer', {
      priceCurrency: 'EUR',
      price: String(product.shopsellprice),
      priceValidUntil: product['maxactiveto'], // type def seems out of date !?
      // "priceValidUntil":  product['productPrices'][0].activeto, // type def seems out of date !?
      availability: 'https://schema.org/InStock'
    });
    const product_obj = this._linkService.getJsonLdObject('Product', {
      ...base_obj,
      name: product.name,
      description: product.shortdescription,
      sku: product.sku,
      Gtin14: product.ean,
      brand: brand,
      offers: offers
    });
    return product_obj;
  }
  add_rating(rating: AggregatedCustomerRating, base_ojb) {
    if (!rating) { return base_ojb }

    const ratingObj = this._linkService.getJsonLdObject('AggregateRating', { ratingCount: rating.ratingcount, reviewCount: rating.ratingcount, ratingValue: rating.averageratingvalue })
    if (rating.averageratingvalue >0)
      return {
        ...base_ojb,
        aggregateRating: ratingObj
      }

    return base_ojb
  }

  addOrderline(orderline: Orderline, product: Product = null, box: Box = null) {
    this._orderService.createNewOrderline(product, box, null, orderline.persons, orderline.quantity, false, false, true, orderline.onetimebox, Flowtype.SINGLEORDER)
  }
  changeProductSelectionQty(value: number, product: Product = null, box: Box = null) {
    const min = 1;

    if (value < min) {
      let index = this._orderService.getOrderlineIndex(product, box, null, false, Flowtype.SINGLEORDER)
      this._orderService.removeOrderlineByIndex(index);
      return;
    }
    this._orderService.changeOrderlineByContent(product, box, null, Flowtype.SINGLEORDER, null, value, false)
  }
  changeBoxPersonQty(value: number, box: Box = null) {
    this._orderService.changeOrderlineByContent(null, box, null, Flowtype.SINGLEORDER, value, null, false)
  }
}
