import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { RouterModule } from '@angular/router';
import { register } from 'swiper/element/bundle';
import { AuthInterceptor } from '../_service/AuthInterceptor';
import { CustomerRatingService } from '../_service/CustomerRatingService';
import { FaqService } from '../_service/FaqService';
import { FavoritesService } from '../_service/FavoritesService';
import { GlobalService } from '../_service/GlobalService';
import { PricingService } from '../_service/PricingService';
import { WINDOW_PROVIDERS } from '../_service/WindowService';
import { AddressComponent } from './address/address.component';
import { AddresseditComponent } from './addressedit/addressedit.component';
import { BottomdrawerDialogComponent } from './bottomdrawerDialog/bottomdrawerDialog.component';
import { BoxpageComponent } from './boxpage/boxpage.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CheckdialogComponent } from './checkdialog/checkdialog.component';
import { ChipComponent } from './chip/chip.component';
import { CmsAccordeonComponent } from './cms-accordeon/cms-accordeon.component';
import { CmsButtonComponent } from './cms-button/cms-button.component';
import { CmsCardComponent } from './cms-card/cms-card.component';
import { CmsEmbedComponent } from './cms-embed/cms-embed.component';
import { CmsLifestyleCardsComponent } from './cms-lifestyle-cards/cms-lifestyle-cards.component';
import { CmsRecipeCardsComponent } from './cms-recipe-cards/cms-recipe-cards.component';
import { CmsSectionComponent } from './cms-section/cms-section.component';
import { CmsStickerComponent } from './cms-sticker/cms-sticker.component';
import { CmsSwiperComponent } from './cms-swiper/cms-swiper.component';
import { CouponcodeComponent } from './couponcode/couponcode.component';
import { CustomerratingComponent } from './customerrating/customerrating.component';
import { FaqComponent } from './faq/faq.component';
import { FavoritesComponent } from './favorites/favorites.component';
import { FooterComponent } from './footer/footer.component';
import { ImageComponent } from './image/image.component';
import { ImpactscoresComponent } from './impactscores/impactscores.component';
import { LifestyleComponent } from './lifestyle/lifestyle.component';
import { LifestylecardComponent } from './lifestylecard/lifestylecard.component';
import { LifestylechosendialogComponent } from './lifestylechosendialog/lifestylechosendialog.component';
import { LoaderComponent } from './loader/loader.component';
import { LoginComponent } from './login/login.component';
import { NotifydialogComponent } from './notifydialog/notifydialog.component';
import { BoxComponent } from './orderline/box/box.component';
import { BoxcardComponent } from './orderline/boxcard/boxcard.component';
import { BoxlineComponent } from './orderline/boxline/boxline.component';
import { EmptycardComponent } from './orderline/emptycard/emptycard.component';
import { EmptylineComponent } from './orderline/emptyline/emptyline.component';
import { ProductComponent } from './orderline/product/product.component';
import { ProductcardComponent } from './orderline/productcard/productcard.component';
import { ProductlineComponent } from './orderline/productline/productline.component';
import { PaymentmethodComponent } from './paymentmethod/paymentmethod.component';
import { CapitalizeFirstPipe } from './pipe/capitalizefirst.pipe';
import { Co2eqPipe } from './pipe/co2eq.pipe';
import { ExkiPipe } from './pipe/exki.pipe';
import { LocalizedDatePipe } from './pipe/localizedDate.pipe';
import { PricetagComponent } from './pricetag/pricetag.component';
import { PricetotalComponent } from './pricetotal/pricetotal.component';
import { RecipeComponent } from './recipe/recipe.component';
import { RecipecardComponent } from './recipecard/recipecard.component';
import { RecipelineComponent } from './recipeline/recipeline.component';
import { RecipelistComponent } from './recipelist/recipelist.component';
import { StoreComponent } from './store/store.component';
import { SubscriptionpricingComponent } from './subscriptionpricing/subscriptionpricing.component';
import { TaxonomynodeComponent } from './taxonomynode/taxonomynode.component';
import { ToggleComponent } from './toggle/toggle.component';
import { VitascoresComponent } from './vitascores/vitascores.component';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsScriptComponent } from './cms-script/cms-script.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { StepComponent } from './stepper/step/step.component';
import { StepperComponent } from './stepper/stepper.component';
import { CmsPopupComponent } from './cms-popup/cms-popup.component';

register();

const imp_exp_list = [
	MatBottomSheetModule,
	MatDialogModule,
	MatInputModule,
	MatSelectModule,
	MatStepperModule,
	
	OverlayModule,
	NgbRatingModule,
];

const comp_list = [
	AddressComponent,
	AddresseditComponent,

	BottomdrawerDialogComponent,
	BoxcardComponent,
	BoxComponent,
	BoxlineComponent,
	BoxpageComponent,

	CalendarComponent,
	CapitalizeFirstPipe,
	CheckdialogComponent,
	Co2eqPipe,
	CouponcodeComponent,
	CustomerratingComponent,
	CmsSectionComponent,
	ExkiPipe,

	FaqComponent,
	FavoritesComponent,
	FooterComponent,

	ImageComponent,
	ImpactscoresComponent,
	VitascoresComponent,

	LifestylecardComponent,
	LifestyleComponent,
	LifestylechosendialogComponent,

	LocalizedDatePipe,
	LoginComponent,

	NotifydialogComponent,

	PaymentmethodComponent,
	PricetagComponent,
	PricetotalComponent,
	ProductcardComponent,
	ProductComponent,
	ProductlineComponent,
	EmptylineComponent,
	EmptycardComponent,

	RecipecardComponent,
	RecipeComponent,
	RecipelineComponent,
	RecipelistComponent,

	StoreComponent,
	SubscriptionpricingComponent,

	StepperComponent,
	StepComponent,

	TaxonomynodeComponent,

	LoaderComponent,
	ChipComponent,
	ToggleComponent,
	CmsSwiperComponent,
	CmsCardComponent,
	CmsRecipeCardsComponent,
	CmsLifestyleCardsComponent,
	CmsButtonComponent,
	CmsAccordeonComponent,
	CmsStickerComponent,
	CmsEmbedComponent,
	CmsScriptComponent,
	CmsPopupComponent
];
@NgModule({
	imports: [
		...imp_exp_list,
		...comp_list,
		CommonModule,
		HttpClientModule,
		ReactiveFormsModule,
		RouterModule,
		FormsModule
	],
	
	providers: [
		FaqService,
		PricingService,
		CustomerRatingService,
		FavoritesService,
		ExkiPipe,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { width: '475px', maxWidth: '80vw', hasBackdrop: true } },
		
		// set language settings based on globalservice
		{ provide: LOCALE_ID, deps: [GlobalService], useFactory: (globalService) => globalService.getLanguage() },
		{ provide: MAT_DATE_LOCALE, deps: [GlobalService], useFactory: (globalService) => globalService.getLanguage() },
		WINDOW_PROVIDERS
	],
	exports: [...imp_exp_list, ...comp_list],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {

}
