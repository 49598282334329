import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { Box, BoxProduct } from '../../../_model/box';
import { GlobalService } from '../../../_service/GlobalService';
import { PricingService } from '../../../_service/PricingService';
import { RecipeService } from '../../../_service/RecipeService';
import { BaseorderlineComponent } from '../baseorderline.component';
import { LocalizedDatePipe } from '../../pipe/localizedDate.pipe';
import { RecipecardComponent } from '../../recipecard/recipecard.component';
import { ChipComponent } from '../../chip/chip.component';
import { ImageComponent } from '../../image/image.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ToggleComponent } from '../../toggle/toggle.component';
import { PricetagComponent } from '../../pricetag/pricetag.component';
import { NgIf, NgFor, LowerCasePipe, CurrencyPipe } from '@angular/common';
import { PersonselectionComponent } from '../../personselection/personselection.component';
import { QuantityselectionComponent } from '../../quantityselection/quantityselection.component';
import { SafeHtmlPipe } from 'src/sharedModule/pipe/safe-html.pipe';
import { RecipelistComponent } from 'src/sharedModule/recipelist/recipelist.component';
import { Recipe } from 'src/_model/recipe';

@Component({
    selector: 'app-box',
    templateUrl: './box.component.html',
    styleUrls: ['./box.component.scss'],
  standalone: true,
  imports: [
     NgIf, NgFor,
     LowerCasePipe, CurrencyPipe,LocalizedDatePipe, SafeHtmlPipe,
     ReactiveFormsModule, TranslateModule,
     PricetagComponent, ToggleComponent, ImageComponent,  ChipComponent, RecipelistComponent, RecipecardComponent,   PersonselectionComponent, QuantityselectionComponent
    ]
})
export class BoxComponent extends BaseorderlineComponent implements OnInit, OnDestroy {

  recipes: Recipe[]

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public _translate: TranslateService,
    public _pricingService: PricingService,
    public  _globalService: GlobalService,
    private _recipeService: RecipeService
  ) {
    super(route, router, _translate, _pricingService, _globalService);
  
    this.loadingsubject.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.loadBoxPeriods(this._box);
    })
  }

  ngOnInit() {
    super.ngOnInit();
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

  loadBoxPeriods(box: Box) {
    if (!box) return;
    box.boxPeriods.forEach(per => {
      this._recipeService.getRecipeList(per.boxRecipes.map(r => r.recipeid)).subscribe(
        result => {
          this.recipes = result;
          per.boxRecipes.forEach(r => {
            r['recipe'] = result.filter(rec => rec.id == r.recipeid)[0]
          })
        }
      )
    })
  }

  getIngredientQuantity(prod: BoxProduct) {
    let qty;
    switch (this.personquantity) {
      case 1:
        qty = prod.onepersquantity;
        break;
      case 2:
        qty = prod.twopersquantity;
        break;
      case 3:
        qty = prod.threepersquantity;
        break;
      case 4:
        qty = prod.fourpersquantity;
        break;
      default:
        qty = prod.twopersquantity;
        break;
    }
    return qty > 0 ? qty : '';
  }
}
