<div *ngIf="!!card" class="card flex flex-col justify-center content-normal md:flex-row md:max-w-full mx-auto rounded-lg md:rounded-l-lg overflow-hidden mt-[30px]">

  <div class="relative flex items-center justify-center overflow-hidden h-[280px] md:h-auto md:min-h-[300px]" *ngIf="card.main_image?.filename?.startsWith('https') || !!video_url" [ngClass]="[card?.main_imagecontain ? 'p-[25px] md:p-[35px]' : '', card.layout === 'fiftyfifty' ? 'md:w-1/2' : 'flex-grow md:w-1/3', card.jumpy_image ? 'relative z-1 md:my-10' : 'z-0', !!video_url ? 'rounded-t-lg md:rounded-3xl' : '']" [style.backgroundColor]="card?.image_bgcolor">
    <img *ngIf="card.main_image?.filename?.startsWith('https')" [ngClass]="[card?.main_imagecontain ? 'object-contain' : 'object-cover', card?.jumpy_image ? 'rounded-xl' : 'rounded-l-xl']" [alt]="card.main_image" class="w-full h-full" [src]="get_src(card?.main_image, false, 600)" loading="lazy" />
    <div class="h-full w-full md:absolute m-auto md:w-[1000%]" *ngIf="!!video_url">
      <iframe title="video_embed" class="md:absolute w-full h-full" width="100%" height="100%" frameborder="0" loading="lazy" [src]="video_url"></iframe>
    </div>
  </div>

  <!-- regular card -->
  <div *ngIf="!card.jumpy_image" class="text-left p-[25px] md:p-[70px]" [ngClass]="[get_classes(card), (!!card.main_image?.filename ? (card.layout === 'fiftyfifty' ? 'md:w-1/2' : 'md:w-2/3') : 'w-full'), card?.text_color ? card.text_color : 'text-e-reg-green']">
    <h3 *ngIf="card?.title" class="font-serif text-e-dark-green">{{ card.title }}</h3>
    <div class="flex flex-col" [innerHTML]="cardbody">
    </div>
    <ng-container *ngFor="let block of card.blocks">
      <div class="flex flex-row items-center">
        <img class="mr-2.5 min-w-[75px]" *ngIf="block.image?.filename" [alt]="block.image" width="75px" [src]="get_src(block?.image,false, null, 75 )" loading="lazy" />
        <div>
          <p class="my-[0] text-e-dark-green"><b>{{ block.title }}</b></p>
          <p class="my-[0] text-e-dark-green">{{ block.subtitle }}</p>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- card with jumpy image -->
  <div *ngIf="card.jumpy_image" class="flex flex-col justify-center text-left p-[25px] mb-[25px] md:py-[70px] md:pl-[170px] md:pr-[70px] md:ml-[-100px] rounded-lg" [ngClass]="[get_classes(card), (card.main_image?.filename || card.video?.cached_url ? (card.layout === 'fiftyfifty' ? 'md:w-1/2 flex-1' : 'md:w-2/3') : 'w-full'), card?.text_color ? card.text_color : 'text-e-reg-green']">
    <h3 *ngIf="card?.title" class="font-serif text-e-dark-green">{{ card.title }}</h3>
    <div class="flex flex-col items-center" [innerHTML]="cardbody">
    </div>
    <ng-container *ngFor="let block of card.blocks">
      <!-- <pre>{{ block | json }}</pre> -->
      <div class="flex flex-row items-center">
        <img class="mr-2.5" *ngIf="block.image?.filename" [alt]="block.image" width="75px" [src]="get_src(block?.image, null, null, 125)" loading="lazy" />
        <div>
          <p class="my-[0] text-e-dark-green"><b>{{ block.title }}</b></p>
          <p class="my-[0] text-e-dark-green">{{ block.subtitle }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!-- <div class="text-left p-[25px] md:p-[70px]" [ngClass]="[get_classes(card), (!!card.main_image?.filename ? (card.layout === 'fiftyfifty' ? 'md:w-1/2' : 'md:w-1/3') : 'w-full'), card?.text_color ? card.text_color : 'text-e-reg-green']"> -->

<!-- <div class="w-full" [ngClass]="['p-[25px] md:p-[35px]' : card?.main_imagecontain]" [style.backgroundColor]="card?.image_bgcolor" *ngIf="!!card.main_image?.filename"></div> -->