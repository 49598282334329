import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf, formatDate } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../_environments/environment';
import { CustomerLifestyle } from '../../_model/customer';
import { Recipe } from '../../_model/recipe';
import { BottomdrawerdialogData } from '../../_model/shared';
import { AuthService } from '../../_service/AuthService';
import { CustomerService } from '../../_service/CustomerService';
import { DrawerService, Drawerposition } from '../../_service/DrawerService';
import { GlobalService } from '../../_service/GlobalService';
import { SubscriptionService } from '../../_service/SubscriptionService';
import { BottomdrawerDialogComponent } from '../bottomdrawerDialog/bottomdrawerDialog.component';
import { FaqComponent } from '../faq/faq.component';
import { ImageComponent } from '../image/image.component';
import { LifestylechosendialogComponent } from '../lifestylechosendialog/lifestylechosendialog.component';
import { RecipeComponent } from '../recipe/recipe.component';
import { RecipecardComponent } from '../recipecard/recipecard.component';
import { RecipelistComponent } from '../recipelist/recipelist.component';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';


@Component({
    selector: 'app-lifestyle',
    templateUrl: './lifestyle.component.html',
    styleUrls: ['./lifestyle.component.scss'],
    standalone: true,
    imports: [
      NgIf, NgFor, NgClass,
      SafeHtmlPipe,
      ImageComponent, FaqComponent,  RecipelistComponent, RecipecardComponent, LifestylechosendialogComponent, RecipeComponent, 
      TranslateModule
    ]
})
export class LifestyleComponent implements OnInit, OnDestroy {

  _env = environment;

  _lifestyle: any; // Lifestyle
  @Input() set lifestyle(value: any) {
    this.loadLifestyle(value);
  }
  scrollStrategy: ScrollStrategy;

  dateToday = formatDate(new Date(), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
  dateNextWeek = formatDate(new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
  activeWeek = "current";

  persons = this._subscriptionService.flowdefaultpersons; 
  meals = this._subscriptionService.flowdefaultrecipes;

  sessionsteps = {
    stepperindex: 0,
    boxindex: 0,
    deliveryinfoindex: 0
  };

  @ViewChild('bottomdrawerTemplateRef', { read: TemplateRef }) bottomdrawerTemplate: TemplateRef<any>;
  //@ViewChild('detailsdrawer') detailsSidenav: MatSidenav;
  @ViewChild('chooselifestyleTemplateRef', { read: TemplateRef }) chooselifestyleTemplate: TemplateRef<any>;
  @ViewChild('lifestylechosendialog') lifestylechosendialog: LifestylechosendialogComponent;
  @ViewChild('recipedetailsdrawer', { static: true }) public recipedetailsdrawer;
  bottomsheetRef: MatBottomSheetRef;
  bottomSheetOpened = false;
  currentUrl: string;

  dialogRef: any;

  imageRecipes: Recipe[] = [];
  shownRecipe: Recipe;

  recipedetailsdrawerShown = false;
  offcanvasDrawer: NgbOffcanvasRef;

  constructor(
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public _globalService: GlobalService,
    public dialog: MatDialog,
    public drawerService: DrawerService,
    private _subscriptionService: SubscriptionService,
    private _bottomSheet: MatBottomSheet,
    private _sso: ScrollStrategyOptions,
    private _customerService: CustomerService,
  ) {
  }

  unsubscribe = new Subject<void>();

  ngOnInit() {
    this.scrollStrategy = this._sso.noop();

    this.drawerService.setDrawer(Drawerposition.bottom, this.recipedetailsdrawer, 'recipedetails-drawer-lifestyle', 'overflow-y-auto bg-white md:mx-[50px] h-[85vh]');

  }

  loadLifestyle(value: any) {
    this._lifestyle = value;
    setTimeout(() => {
      this.openBottomsheet();
    }, 1500);
  }

  changePersonQty(qty: number) {
    if (Number(this.persons.value) + qty <= this._globalService.maxSubscriptionPersons && Number(this.persons.value) + qty >= this._globalService.minSubscriptionPersons) {
      this.persons.setValue(Number(this.persons.value) + qty);
      this.setSubscriptionObject();
    }
  }

  changeMealQty(qty) {
    if (Number(this.meals.value) + qty <= this._globalService.maxSubscriptionMeals && Number(this.meals.value) + qty >= this._globalService.minSubscriptionMeals) {
      this.meals.setValue(Number(this.meals.value) + qty);
      this.setSubscriptionObject();
    }
  }

  chooseLifestyle() {
    this.closeBottomsheet();
    this.lifestylechosendialog.navigateToPersonalmenu(this.meals.value, this.persons.value, this._lifestyle)
  }

  setSubscriptionObject() {

    this._customerService.getCustomerLifestyles.clear();
    this._customerService.getCustomerFlowLifestyles.clear();

    this._customerService.addLifeStyle(new CustomerLifestyle(null, this._lifestyle.id))
    //this.sessionsteps.boxindex = 1;
    //this._subscriptionService.customerLifestyles.clear();
    //this._subscriptionService.addNewSubscriptionCustomerLifestyle(new CustomerLifestyle(null, this._lifestyle.id));
    //this._sessionStorage.setObject('newsubscriptionsteps',this.sessionsteps);
  }

  toggleRecipeDrawer(recipe: Recipe = null) {
    if (!this.recipedetailsdrawerShown && recipe) {
      this.shownRecipe = recipe;
      this.recipedetailsdrawerShown = true
      this.offcanvasDrawer = this.drawerService.toggle('recipedetails-drawer-lifestyle');
      this.offcanvasDrawer.dismissed.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        this.recipedetailsdrawerShown = false;
        this.shownRecipe = null;
      })
    } else {
      this.offcanvasDrawer.dismiss();
      this.recipedetailsdrawerShown = false;
      this.shownRecipe = null;
    }
  }

  setImageRecipes(recipes: Recipe[]) {
    if (!recipes) { return; }
    setTimeout(() => {
      this.imageRecipes = recipes.slice(0, 3);      
    });

  }

  openBottomsheet() {
    this.createBottomSheet();
  }
  
  closeBottomsheet() {
    if (this.bottomsheetRef) {
      this.bottomsheetRef.dismiss();
      this.bottomSheetOpened = false;
    }
  }
  createBottomSheet() {
    if (this.authService.isAuthenticated()) { return; }
    //this has to be implemented, only the personalmenu flow isnt ready yet
    if (this._subscriptionService.hasSubscriptions) { return; }
    
    if (!this.bottomSheetOpened) {
      this.bottomsheetRef = this._bottomSheet.open(BottomdrawerDialogComponent, {
        data: new BottomdrawerdialogData(this.bottomdrawerTemplate),
        hasBackdrop: false,
        disableClose: true, closeOnNavigation: false,
        scrollStrategy: this.scrollStrategy
      });
      this.bottomSheetOpened = true;

      this.bottomsheetRef.afterOpened().subscribe(() => {
        this.bottomSheetOpened = true;
      });
      this.bottomsheetRef.afterDismissed().subscribe(() => {
        this.bottomSheetOpened = false;
      });

    }
  }

  ngOnDestroy() {
    if (this.bottomSheetOpened) {
      this.bottomsheetRef.dismiss();
    }
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }



}
