<div class="container-default mt-[-50px]" *ngIf="section?.layout === 'IMGONLY'">  
  <img *ngIf="!!section.main_image?.filename" [alt]="get_alt(section.main_image)" class="max-h-[750px] w-full object-cover rounded-xl" [src]="get_src(section.main_image, false, '800')" [attr.loading]="index === 0 ? 'eager' : 'lazy'" />
</div>

<ng-container *ngIf="section?.layout">
  <div *ngIf="section.layout !== 'IMGONLY'" 
  class="relative" 
  [ngClass]="[  get_classes(section),   section.condensed ? 'py-[15px] md:p-[50px]' : 'p-[50px] lg:p-[100px]',  inPopup? 'px-[5px]': 'px-[25px]'  ]">
    <div *ngIf="section?.layout.includes('IMG_')" class="relative flex flex-col justify-between w-full max-w-[1428px] mx-auto" [ngClass]="get_direction(section)">
      <div class="flex flex-col flex-grow justify-center mt-[25px] md:mt-0" [ngClass]="section?.layout !== 'IMG_HERO' ? 'md:w-1/2' : 'md:w-1/3'">
        <div *ngIf="!!section?.title" [innerHTML]="sectionTitle"></div>
        <p class="font-medium text-md" *ngIf="!!section.subtitle">{{ section.subtitle }}</p>
        <p *ngIf="section?.body?.content[0]?.content" [innerHTML]="sectionBody"></p>
        

        <ng-container *ngFor="let block of section.blocks">
          
          <app-cms-embed *ngIf="block.component === 'embed'" [embed]="block"></app-cms-embed>
          <app-cms-accordeon *ngIf="block.component === 'accordeon'" [accordeon]="block"></app-cms-accordeon>
          <app-cms-button *ngIf="block.component === 'CTA'" [button]="block"></app-cms-button>
          <app-cms-sticker *ngIf="block.component === 'sticker'" [sticker]="block" ></app-cms-sticker>
          <app-cms-section *ngIf="block.component === 'section'" [section]="block" [index]="(index + 1)"></app-cms-section>
        </ng-container>

      </div>
      <div class="md:w-[150px]"></div>
      <div class="pt-[25px] relative" [ngClass]="section?.layout === 'IMG_HERO' ? 'md:w-2/3 md:min-h-[500px]' : 'md:w-1/2'">
        <div class="flex rounded-3xl overflow-hidden min-h-[300px] h-full" [ngClass]="section.main_image_contain ? 'bg-' + section.main_image_bgcolor : ''">
          <img *ngIf="!!section.main_image?.filename" class="max-h-[600px]" [ngClass]="section.main_image_contain ? 'm-auto' : 'object-cover w-full rounded-3xl'" [alt]="get_alt(section.main_image)" [src]="get_src(section.main_image, section.main_image_contain, 600)"  [attr.loading]="index === 0 ? 'eager' : 'lazy'" />
        </div>

        <img *ngIf="!!section.sticker?.filename" class="absolute top-[50px] left-[25px] h-[100px] w-[100px] md:top-[75px] md:left-[50px] md:h-[150px] md:w-[150px]" [alt]="get_alt(section.sticker)" [src]="get_src(section.sticker,false, 150)"  [attr.loading]="index === 0 ? 'eager' : 'lazy'" />
        <div class="flex justify-center md:hidden" *ngIf="section?.layout === 'IMG_HERO'">
          <div class="icon-round relative top-[-20px]"><img alt="arrow-down" class="h-5 w-5" src="./assets/images/icons/arrow_down.svg" /></div>
        </div>
      </div>

    </div>

    <!-- TEXT ONLY -->
    <div *ngIf="section?.layout.includes('TEXT')" class="relative sm:text-left md:text-center max-w-[1428px] mx-auto" [ngClass]="section.width">
      <div [ngClass]="section.cols === '2' ? 'md:grid md:grid-cols-2 gap-[25px]' : ''">
        <img *ngIf="!!section.main_image?.filename" [alt]="get_alt(section.main_image)" class="mx-auto mb-[25px] md:mb-[50px] rounded-lg max-w-[200px]" [src]="get_src(section.main_image, null, null, 200)"  [attr.loading]="index === 0 ? 'eager' : 'lazy'"/>
        <div *ngIf="!!section?.title" [innerHTML]="sectionTitle"></div>
        <p *ngIf="!!section?.subtitle" class="font-medium text-md">{{ section.subtitle }}</p>        
        <p *ngIf="!!section?.body" [innerHTML]="sectionBody"></p>
        
        <ng-container *ngFor="let block of section.blocks">
    
          <app-cms-card *ngIf="block.component === 'card'" [card]="block"></app-cms-card>
          <app-cms-recipe-cards *ngIf="block.component === 'RecipeCards'" [card]="block" class="block mb-[25px]"></app-cms-recipe-cards>
          <app-cms-lifestyle-cards *ngIf="block.component === 'LifestyleCards'" [card]="block"></app-cms-lifestyle-cards>
    
          <app-cms-swiper *ngIf="block.component === 'slider'" [block]="block"></app-cms-swiper>
          <app-cms-accordeon *ngIf="block.component === 'accordeon'" [accordeon]="block"></app-cms-accordeon>
          <app-cms-button *ngIf="block.component === 'CTA'" [button]="block"></app-cms-button>
          <app-cms-sticker *ngIf="block.component === 'sticker'" [sticker]="block"></app-cms-sticker>
          <app-cms-embed *ngIf="block.component === 'embed'" [embed]="block"></app-cms-embed>
          <div class="mt-10 md:mt-[90px]" *ngIf="block.component === 'section'">
            <app-cms-section [section]="block" [index]="(index + 1)"></app-cms-section>
          </div>
        </ng-container>
      </div>
      
    </div>
  </div>
</ng-container>

<ng-container *ngIf="section?.component === 'script'">
  <app-cms-script [script]="section"></app-cms-script>
</ng-container>


