import { Component, OnInit, Input } from '@angular/core';
import { CmsService } from 'src/_service/CmsService';
import { RecipecardComponent } from '../recipecard/recipecard.component';
import { RecipelistComponent } from '../recipelist/recipelist.component';
import { NgIf } from '@angular/common';
import { PlaceholderComponent } from '../placeholder/placeholder.component';

@Component({
    selector: 'app-cms-recipe-cards',
    templateUrl: './cms-recipe-cards.component.html',
    standalone: true,
    imports: [NgIf, RecipelistComponent, RecipecardComponent, PlaceholderComponent]
})
export class CmsRecipeCardsComponent implements OnInit {

  @Input() card: any;
  formatted_today: string;
  formatted_nextweek: string;
  selected_lifestyles = [];
  selected_allergens = []; // TODO
  
  constructor(private _cmsService: CmsService) {
  }


  ngOnInit() {
    const today = new Date();
    const nextWeek = new Date();
    nextWeek.setDate(today.getDate() + 8); // 8 days a week ??
    this.formatted_today = today.toISOString().split('T')[0];
    this.formatted_nextweek = nextWeek.toISOString().split('T')[0];
    
    // console.log(this.card);
    if( this.card.lifestyles ){
      this.selected_lifestyles = this.card.lifestyles.map(item => Number(item)) 
    }
    if( this.card.allergens ){
      this.selected_allergens = this.card.allergens.map(item => Number(item)) 
    }
  }

  // render_rt(content) {
  //   return this._cmsService.render_rt(content);
  // }

  get_alt(field) {
    return this._cmsService.get_alt(field);
  }

  get_sanitized_url(url) {
    return this._cmsService.get_sanitized_url(url);
  }

  get_src(field, contain, height?, width?) {
    return this._cmsService.get_src(field, contain, height, width);
}

  get_classes(card) {
    return this._cmsService.get_classes(card);
  }

}



