import { LowerCasePipe, NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { environment } from '../../_environments/environment';
import { CustomerLifestyle } from '../../_model/customer';
import { Lifestyle } from '../../_model/recipe';
import { Entitytype } from '../../_model/redirect';
import { GlobalService } from '../../_service/GlobalService';
import { RedirectService } from '../../_service/RedirectService';
import { SessionstorageService } from '../../_service/SessionstorageService';
import { SubscriptionService } from '../../_service/SubscriptionService';
import { collapse } from '../../_theme/animations';
import { LifestylechosendialogComponent } from '../lifestylechosendialog/lifestylechosendialog.component';
import { Flowtype } from '../../_model/order';
import { CustomerService } from '../../_service/CustomerService';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';

@Component({
    selector: 'lifestylecard',
    templateUrl: './lifestylecard.component.html',
    styleUrls: ['./lifestylecard.component.scss'],
    animations: [collapse],
    standalone: true,
    imports: [
      NgClass, NgIf,
       RouterLink, LifestylechosendialogComponent, 
       LowerCasePipe, SafeHtmlPipe, 
       TranslateModule
      ]
})
export class LifestylecardComponent implements OnInit, OnDestroy {

  environment = environment;

  _lifestyle: Lifestyle;
  @Input() set lifestyle(value: Lifestyle) {
    this._lifestyle = value;
    this._redirectService.getEntityUrl(Entitytype.LIFESTYLE, value.id, value.redirectid).then(value => this._lifestyle['lifestyleurl'] = value)
  }

  @Input() set selectedLifestyle(value: Lifestyle) {
    this.selected = value === this._lifestyle;
  }

  _flowtype: Flowtype = Flowtype.PERSONALMENU
  @Input() set flowtype(value: Flowtype | string) {
    if (value) {
      this._flowtype = Flowtype[value];
    } else {
      this._flowtype = Flowtype.PERSONALMENU;
    }
      
  };
  @Input() extendable: boolean = false;
  @Input() index;

  @Output() selectedLifestyleChange = new EventEmitter<Lifestyle>();
  @Output() showLifestyle = new EventEmitter<Lifestyle>();

  editable = true;
  persons = this._subscriptionService.flowdefaultpersons; 
  meals = this._subscriptionService.flowdefaultrecipes;
  disableShowAction: boolean;
  selected = false;

  sessionsteps = {
    stepperindex: 0,
    boxindex: 0,
    deliveryinfoindex: 0
  };

  @ViewChild('lifestylechosendialog') lifestylechosendialog: LifestylechosendialogComponent;

  constructor(
    public activatedRoute: ActivatedRoute,
    private _subscriptionService: SubscriptionService,
    private _router: Router,
    private _redirectService: RedirectService,
    public _globalService: GlobalService,
    public fb: FormBuilder,
    private _sessionStorage: SessionstorageService,
    private _customerService: CustomerService
  ) {
  }

  unsubscribe = new Subject<void>();

  ngOnInit(): void {
    if (this.showLifestyle.observers.length > 0) {
      this.disableShowAction = false;
    } else {
      this.disableShowAction = true;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }

  hasError = (formGroup: FormGroup, controlName: string, errorName: string) => {
    return formGroup.get(controlName).hasError(errorName);
  }

  showLifestyleAction() {
    this.setLifestyle();
    this.showLifestyle.emit(this._lifestyle);
  }

  changePersonQty(qty) {
    if (this.persons.value + qty <= this._globalService.maxSubscriptionPersons && this.persons.value + qty >= this._globalService.minSubscriptionPersons) {
      this.persons.setValue(this.persons.value + qty);
      this.setLifestyle();
    }
  }

  changeMealQty(qty) {
    if (this.meals.value + qty <= this._globalService.maxSubscriptionMeals && this.meals.value + qty >= this._globalService.minSubscriptionMeals) {
      this.meals.setValue(this.meals.value + qty);
      this.setLifestyle();
    }
  }

  toggleLifestyle() {
    this.selected = !this.selected;
    this.selectedLifestyleChange.emit(this.selected ? this._lifestyle : null);

    this.setLifestyle();

    if (this.selected) {
      this._router.navigate(['/personalmenu'], { queryParams: { step: 'box-1' } });
    }
  }

  setLifestyle() {
    this._customerService.addLifeStyle(new CustomerLifestyle(null, this._lifestyle.id))
  }

  chooseLifestyle() {
    if (this._flowtype === Flowtype.PERSONALMENU)
      this.lifestylechosendialog.navigateToPersonalmenu(this.meals.value, this.persons.value, this._lifestyle)

    this.selectedLifestyleChange.emit(this._lifestyle)
  }




}
