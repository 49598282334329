import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from 'src/_service/CmsService';
import { CmsLifestyleCardsComponent } from '../cms-lifestyle-cards/cms-lifestyle-cards.component';
import { CmsRecipeCardsComponent } from '../cms-recipe-cards/cms-recipe-cards.component';
import { CmsCardComponent } from '../cms-card/cms-card.component';
import { CmsStickerComponent } from '../cms-sticker/cms-sticker.component';
import { CmsSwiperComponent } from '../cms-swiper/cms-swiper.component';
import { CmsButtonComponent } from '../cms-button/cms-button.component';
import { CmsAccordeonComponent } from '../cms-accordeon/cms-accordeon.component';
import { CmsEmbedComponent } from '../cms-embed/cms-embed.component';
//import { SharedModule } from '../shared.module';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { CmsScriptComponent } from '../cms-script/cms-script.component';
import { CmsPopupComponent } from '../cms-popup/cms-popup.component';

@Component({
  selector: 'app-cms-section',
  templateUrl: './cms-section.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    //SharedModule,
    CmsEmbedComponent,
    CmsAccordeonComponent,
    CmsButtonComponent,
    CmsStickerComponent,
    CmsCardComponent,
    CmsRecipeCardsComponent,
    CmsLifestyleCardsComponent,
    CmsSwiperComponent,
    CmsScriptComponent
  ],
  host: { ngSkipHydration: 'true' },
})
export class CmsSectionComponent {

  sectionTitle: any;
  sectionBody: any;
  private _section: any
  @Input() set section(value: any) {
    this._section = value;
    if (value.title)
      this.sectionTitle = this._cmsService.render_rt(value.title)
    if (value.body)
      this.sectionBody = this._cmsService.render_rt(value.body)
  };
  get section() { return this._section };

  @Input() index: number = 0;
  @Input() inPopup: boolean = false;
  
  constructor(
    private _cmsService: CmsService
  ) {
  }

  get_src_as_css(field, contain) {
    return this._cmsService.get_src_as_css(field, contain)
  }

  get_src(field, contain, height?, width?) {
    return this._cmsService.get_src(field, contain, height, width);
}

  get_alt(field) {
    return this._cmsService.get_alt(field);
  }

  get_direction(section) {
    return this._cmsService.get_direction(section);
  }

  get_classes(block) {
    return this._cmsService.get_classes(block);
  }

  get_content(field) {
    return this._cmsService.get_content(field);
  }

}

