<div class="container-default pt-0 " [ngClass]="{'px-2 md:px-[initial]': isfullscreen}">
  <div class="flex flex-row justify-start md:justify-center mt-5 md:mt-[15px] p-[5px]">
    <h3 class="text-center mt-2.5">
      <ng-container *ngIf="productSearch.value && productSearch.value.length >=2; else nodetitle"> {{'taxonomy.search'  | translate}} </ng-container>
      <ng-template #nodetitle>{{_taxonomynode?.displayname}}</ng-template>
    </h3>
  </div>
  <div class="g-0 store_top z-10 pb-2.5" [ngClass]="{'sticky top-[33px] md:top-[137px] bg-e-white': isfullscreen}">
    <div class="flex flex-row justify-between">
      <div>
        <button class="button rounded-full cursor-pointer" [disabled]="_previousTaxonomyNodes.length === 0 " (click)="gotoPreviousNode(_previousTaxonomyNodes[_previousTaxonomyNodes.length-1])">
          <i class="bi bi-arrow-left">{{'common.back_button'|translate}}</i>
        </button>
        <div class="breadcrump_container">
          <a (click)="gotoPreviousNode(node)" *ngFor="let node of _previousTaxonomyNodes" class="font-semibold text-e-dark-green cursor-pointer">
            <small>{{node.displayname}} > </small>
          </a>
          <span>
            <small *ngIf="productSearch.value && productSearch.value.length >= 2; else nodebreadcrumbtitle"> {{'taxonomy.search'  | translate}} </small>
            <ng-template #nodebreadcrumbtitle><small>{{_taxonomynode?.displayname}}</small></ng-template>
          </span>
        </div>
      </div>
    </div>
    <div class="search_container">
      <div class="form-field mt-1">
        <input type="search" #search tabindex="{{ 0}}" matInput placeholder="{{'taxonomy.search_product' | translate}}" name="search_product" required [formControl]="productSearch" id="productsearch">
        <button *ngIf="productSearch.value" (click)="productSearch.markAsDirty(); productSearch.setValue(null)" class="absolute right-[15px] top-2.5 z-[1]"><i class="text-2xl bi bi-x-circle"></i></button>
        <span class="hint" *ngIf="hasError('minlength')">{{'taxonomy.search_minlength_2' | translate}}</span>
      </div>
    </div>
  </div>
  
  <small class="flex flex-row" *ngIf="nodeError || productError || boxError">
    <span class="text-center error">{{'taxonomy.error_not_everything_is_shown' | translate}}</span>
  </small>
  <div *ngIf="!productSearch.value || productSearch.value.length <= 1; else results"
       class="grid bg-e-white rounded-lg min-h-[75vh] parentgrid {{isfullscreen? 'grid-cols-2 gap-2 p-0 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4': 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[25px] p-[25px]'  }}"
       id="childnodes">
    <div *ngIf="loading" class="flex flex-row justify-center my-[50px]">
      <app-loader></app-loader>
    </div>
    <ng-container *ngFor="let child of _taxonomynode?.taxonomyNodeChildNodes;let i=index">
      <div id="childnode-{{i}}" *ngIf="!child?.childtaxonomynode?.hidden && child?.childtaxonomynode?.enabled">
        <div class="flex justify-center items-center relative rounded-lg min-h-[200px] md:min-h-[338px] overflow-hidden h-100 w-100 taxonomynode cursor-pointer" (click)="navigateToNode(child?.childtaxonomynode)">
          <app-image class="absolute top-0 right-0 left-0 bottom-0 min-h-[338px] object-cover" size="lg" [imageobject]="child.childtaxonomynode"></app-image>
          <h3 class="text-e-dark-green text-xl px-2.5 z-[5] text-shadow">{{child?.childtaxonomynode?.displayname}}</h3>
        </div>
      </div>
    </ng-container>

    <ng-container *ngTemplateOutlet="productlist; context: {'list':getChilds}"></ng-container>
  </div>
  <ng-template #results>
    <div class="grid bg-e-white rounded-lg mt-[25px] min-h-[75vh] resultsgrid {{isfullscreen? 'grid-cols-2 gap-2 p-0 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4': 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-[25px] p-[25px]'  }}">
      <div class="flex flex-col justify-center" *ngIf="searchResults && searchResults?.length === 0 && !loading">
        <p class="text-center" *ngIf="!searchError">{{'taxonomy.no_products_found' | translate}}</p>
        <p class="text-center" *ngIf="searchError">{{'taxonomy.internal_server_error' | translate}}</p>
      </div>
      <ng-container #loading *ngIf="loading">
        <app-loader></app-loader>
      </ng-container>
      <ng-container *ngTemplateOutlet="productlist; context: {'list':searchResults}"></ng-container>
    </div>
  </ng-template>

  <ng-template #productlist let-list="list">

    <span class="text-center error" *ngIf="list && list.length === 0">{{'taxonomy.no_products_found' | translate}}</span>

    <ng-container *ngFor="let child of list;let i=index">
      <div *ngIf="child.product && child.product.shopsellprice && child.product.availableforwebshop || (child.box && child.box.boxcountryprices[0] && child.box.availableforwebshop) && (!isfullscreen || isfullscreen && child.box && child.box.boxcountryprices[0] && child.box._availableforsingleorder)" id="nodecontainer-{{i}}">
        <ng-container *ngIf="child.product; else boxResult">
          <ng-container *ngTemplateOutlet="productTemplate;context:{$implicit:child.product}"></ng-container>
        </ng-container>
        <ng-template #boxResult>
          <ng-container *ngTemplateOutlet="boxTemplate;context:{$implicit:child.box}"></ng-container>
        </ng-template>
      </div>
    </ng-container>

  </ng-template>
</div>
