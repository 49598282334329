
<div class="max-w-6xl px-[25px] md:mx-auto">
  <div class="flex flex-col">
    <app-cms-page [story]="topstory"></app-cms-page>

    <!--<div class="block mx-auto">STORYBLOCK CONTENT HERE</div>-->
    <div class="lg:mx-[100px]">
      <app-lifestyleselection [lifestyleid]="lifestylesCtrl.value" (lifestyleidChange)="lifestylesCtrl.setValue($event)" class=""></app-lifestyleselection>
    </div>
    <div class="mt-12 flex flex-col md:flex-row items-center justify-between">
      <div class="flex flex-row mb-4 md:mb-0">
        <button (click)="previousweek()" class="px-2"><img src="/assets/images/icons/chevron.svg" alt="previous-week" class="-rotate-180" /></button>
        <h2 class="text-lg">
          <span class="me-1">{{'orderflow.recipeselection.menu_for' |translate}}</span>
          <span class="text-e-orange">{{nextThursday | localizedDate :'d MMM' |lowercase}} - {{nextWeekWednesday | localizedDate :'d MMM' |lowercase}}</span>
        </h2>
        <button (click)="nextweek()" class="px-2"><img src="/assets/images/icons/chevron.svg" alt="next-week" /></button>
      </div>

      <div class="form-field mx-[25px] w-[325px]">
        <mat-select class="placeholder:first-letter:uppercase" [formControl]="allergensCtrl" id="allergens" matInput multiple placeholder="{{'allergen.allergens' | translate}}" type="text">
          <mat-option *ngFor="let allergen of allergens" [value]="allergen" class="placeholder:first-letter:uppercase">
            {{'allergen.' + allergen |translate}}
          </mat-option>
        </mat-select>
      </div>
    </div>
  </div>


  <div class="container-default px-[25px] mt-[25px] md:mx-auto md:mt-[25px]">
    <recipe-list [deliverydate]="nextThursday"
                 (recipesChange)="recipes = $event"
                 [lifestyles]="[lifestylesCtrl.value]"
                 [allergens]="allergensCtrl.value">
      <ng-template #recipe let-recipe>
        <recipecard [recipe]="recipe"
                    [status]="'ONBOARD'"
                    [selectedlifestyles]="[lifestylesCtrl.value]"
                    [selectedallergens]="allergensCtrl.value"
                    (showRecipe)="toggleRecipeDrawer($event)">
        </recipecard>
      </ng-template>

    </recipe-list>

    <ng-container *ngIf="recipes?.length === 0 ">
      <div class="flex flex-row  mt-5 mb-5 justify-center" id="busychef">
        <img alt="Onze Chef" class="h-[150px] " src="assets/images/chef-menu.svg" />
      </div>
      <div class="mb-5 w-full mt-3 text-center block">{{'subscription.still_creating_menu'| translate}}</div>
    </ng-container>
  </div>
  <div class="flex flex-col">
    <app-cms-page [story]="bottomstory"></app-cms-page>
  </div>
</div>

<ng-template #recipedetailsdrawer>
  <button type="button" class="flex items-center justify-center absolute h-9 w-9 rounded-full border-solid border-2 border-e-dark-green top-[25px] right-[35px]" aria-label="Close" (click)="toggleRecipeDrawer()">
    <img src="./assets/images/icons/close.svg" height="22" width="22" />
  </button>

  <div class="bg-white md:px-[25px] py-[75px] md:pt-[75px]">
    <app-recipe [recipe]="shownRecipe" [popup]="true"></app-recipe>
  </div>
</ng-template>


