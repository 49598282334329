<footer class="bg-e-white overflow-hidden text-white">
  <div class="bg-e-dark-green px-[25px] md:pl-[100px]">
    <div class="py-10 flex flex-col md:py-[90px] xl:flex-row">
      <div class="flex w-full flex-col md:flex-row xl:w-2/3">
        <div class="grid-cols-1 grid md:grid-cols-[repeat(3,_minmax(300px,_1fr))]" *ngIf="!!content">
          <div class="mt-2.5 flex justify-center md:justify-start" *ngFor="let block of content.blocks">
            <app-cms-button *ngIf="block.component === 'CTA'" [button]="block"></app-cms-button>
          </div>
        </div>
      </div>
      <div class="mt-9 flex w-full flex-col md:flex-row md:justify-center md:items-center xl:mt-0 xl:w-1/3">
        <img src="/assets/images/bio.webp" alt="bio-logo" class="mx-auto max-h-24 max-w-24 xl:ml-15" loading="lazy" />
        <img src="/assets/images/b-corp-white.svg" alt="bcorp-logo" class="mx-auto max-h-24 max-w-24 xl:ml-5" loading="lazy" />
      </div>
    </div>
  </div>

  <div class="px-[25px] py-10 flex flex-col items-center justify-between md:px-[100px] md:flex-row">

    <div class="py-[27px]">
      <img [src]="env.logo" width="137" alt="ekomenu logo" loading="lazy" />
      <span class="text-e-dark-green block text-center font-sans text-xs font-medium uppercase tracking-widest">{{ env.subtitle | translate }}</span>
    </div>      

    <div class="text-e-dark-green flex flex-col items-center md:flex-row-reverse">
      <div class="flex flex-row">
        <a *ngFor="let social of socials" class="pr-[15px] mb-[50px] md:mb-0" title="{{ social.title }}" href="{{social.url}}" target="_blank">
          <img height="25" width="25" alt="{{social.title}}" src="{{social.src}}" loading="lazy" />
        </a>
      </div>
      <p class="mr-[35px]">© {{ currentYear }}. All rights Reserved. </p>
    </div>
  </div>

</footer>
