<div class="{{data.color}}">
  <div class="m-3">
    <h3>
      {{data.title}}
    </h3>
  </div>
 
  <div mat-dialog-content>
    {{data.description}}
  </div>
  <div mat-dialog-actions class="mt-3">
    <ng-container [ngSwitch]="data.dialogtype">
      <ng-container *ngSwitchCase="'OkOnly'">
        <button class="button-base rounded-full" [mat-dialog-close]="'ok'" cdkFocusInitial >{{'common.ok_dismiss' | translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'YesNo'">
        <button class="button-base rounded-full bg-e-white text-e-dark-green me-2 " [mat-dialog-close]="'no'">{{'common.no_button' | translate}}</button>
        <button class="button-base rounded-full " [mat-dialog-close]="'yes'" cdkFocusInitial >{{'common.yes_button' | translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'YesNoCancel'">
        <button class="button-base rounded-full bg-e-white text-e-dark-green me-2 " [mat-dialog-close]="'cancel'">{{'common.cancel_button' | translate}}</button>
        <button class="button-base rounded-full bg-e-white text-e-dark-green  me-2 " [mat-dialog-close]="'no'">{{'common.no_button' | translate}}</button>
        <button class="button-base rounded-full" [mat-dialog-close]="'yes'" cdkFocusInitial >{{'common.yes_button' | translate}}</button>
      </ng-container>
      <ng-container *ngSwitchCase="'Custom'">
        <div class="">
          <button class="button-base rounded-full  me-2" [ngClass]="{'bg-e-white text-e-dark-green': i !== 0}" *ngFor="let btn of data.custombuttons; let i=index" cdkFocusInitial="i === 0" [mat-dialog-close]="btn.value">{{btn.displayvalue | translate}}</button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
