<div class="container-default p-0 md:p-[25px] flex flex-col md:flex-row justify-center">
  <!-- This is a fixed slide, undouble later on -->
    <div class="flex flex-col items-center justify-center p-[25px] md:p-10 md:mr-5 rounded-lg md:min-w-[400px] md:max-w-[400px]" [ngClass]="!!fixedBlock?.bg_color ? fixedBlock.bg_color : ''" *ngIf="!!fixedBlock">
      <img *ngIf="!!fixedBlock.main_image?.filename" [alt]="get_alt(fixedBlock.main_image)" class="inline-block mx-auto aspect-auto h-[125px] my-[15px]" [src]="get_src(fixedBlock.main_image, null, 125)" loading="lazy" />        
        
        <div 
          *ngIf="block.show_numbers && !fixedBlock.hide_number" 
          [ngClass]="{'absolute top-20 left-0': !isMobile}"
          class="flex items-center justify-center min-h-8 min-w-8 rounded-full bg-e-dark-green text-e-reg-green mr-5">
          <span class="font-serif pt-1">{{ 1 }}</span>
        </div>
        
        <div *ngIf="fixedBlock?.title" [innerHTML]="fixedBlock.title_rendered"></div>

        <ng-container *ngFor="let block of fixedBlock.blocks">
          <app-cms-card *ngIf="block.component === 'card'" [card]="block"></app-cms-card>
          <app-cms-button *ngIf="block.component === 'CTA'" [button]="block"></app-cms-button>
        </ng-container>
    </div>
  
  <swiper-container class="max-w-full" #swiperRef init="false">
    <swiper-slide *ngFor="let slide of block.slides; let i = index">
      <div class="flex flex-col h-full py-[30px] md:px-10 rounded-lg" [ngClass]="!!slide?.bg_color ? slide.bg_color : ''">
        <img *ngIf="!!slide.main_image?.filename" [alt]="get_alt(slide.main_image)" class="inline-block mx-auto aspect-auto h-[125px] my-[15px]" [src]="get_src(slide.main_image, null, 125)" loading="lazy" />        
        <div class="flex flex-row justify-center items-center text-center">
          
          <div 
            *ngIf="block.show_numbers && !slide.hide_number" 
            [ngClass]="{'absolute top-5 left-0': !isMobile}"
            class="flex items-center justify-center min-h-8 min-w-8 rounded-full bg-e-dark-green text-e-reg-green mr-5">
            <span class="font-serif pt-1">{{ i + 1 }}</span>
          </div>
          
          <div *ngIf="slide?.title" [innerHTML]="slide.title_rendered"></div>

          <ng-container *ngFor="let block of slide.blocks">
            <app-cms-card class="w-full" *ngIf="block.component === 'card'" [card]="block"></app-cms-card>
            <app-cms-button *ngIf="block.component === 'CTA'" [button]="block"></app-cms-button>
          </ng-container>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>  
</div>
    <!-- If we need navigation buttons -->
  <ng-container *ngIf="!isMobile && block.navigation_type === 'arrows'">
    
    <div class="flex flex-row justify-center items-center relative bottom-0 select-none">
      <div class="swiper-button-prev flex flex-row justify-center items-center">
        <img class="h-4 w-7 rotate-180" alt="next slide" src="./assets/images/icons/arrow_right.svg" />
      </div>
      <div class="swiper-button-next flex flex-row justify-center items-center">
        <img class="h-4 w-7" alt="previous slide" src="./assets/images/icons/arrow_right.svg" />
      </div>    
    </div>
  </ng-container>
<p *ngIf="block?.caption" class="md:text-right">{{ block.caption }}</p>
