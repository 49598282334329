<div class="max-w-[300px] {{class}}">
  <!-- <span class="col-9 mt-2">{{calendardays[15].month_name}}</span> -->
  <div class="flex flex-row justify-between">
    <small class="font-bold">{{calendardays[15].date | date: 'MMMM yyyy'}}</small>
    <div>
      <button (click)="previousMonth()" [disabled]="allowPreviousMonth()" class="mr-2.5 disabled:opacity-25">
        <img src="./assets/images/icons/arrow_down.svg" height="20px" class="rotate-90">
      </button>
      <button (click)="nextMonth()">
        <img src="./assets/images/icons/arrow_down.svg" height="20px" class="rotate-[270deg]">
      </button>
    </div>
  </div>
  <div class="row header">
    <ng-container *ngFor="let day_info of calendardays; let i=index ">
      <div *ngIf="i <= 6" class="text-center">
        <!-- <small>{{day_info.date | date: 'E' }}</small> -->
        <small>{{day_info.date | date: 'E' }}</small>
      </div>
    </ng-container>
  </div>

  <div class="calendar">
    <ng-container *ngFor="let day_info of calendardays | slice:0:40">
      <div (click)="dateClicked(day_info)"   
      [ngClass]="(day_info.month !== currentMonth || day_info.isdisabled || day_info.istoday || !day_info.isavailable) ? 'bg-transparent opacity-25' : isActivedate(day_info) ? 'bg-e-dark-green text-e-white' : 'bg-white text-e-dark-green cursor-pointer'"  
        class="flex justify-center items-center h-[39px] w-[39px] mr-[5px] mb-[5px] rounded-full">
        <ng-container *ngIf="day_info.month === currentMonth">{{day_info.formatted_day}}</ng-container>
      </div>
    </ng-container>
  </div>

</div>

<!-- [ngClass]="{'bg-e-dark-green text-e-white': isActivedate(day_info), 'bg-transparent': day_info.month !== currentMonth, 'bg-transparent': !day_info.isavailable, 'bg-transparent': day_info.isdisabled, 'today': day_info.istoday}"  -->