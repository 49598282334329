import { Component, Input } from '@angular/core';
import { PlaceholderPreset } from 'src/_model/shared';

@Component({
  selector: 'app-placeholder',
  standalone: true,
  imports: [],
  templateUrl: './placeholder.component.html',
  styleUrl: './placeholder.component.scss'
})
export class PlaceholderComponent {
  @Input()  preset: PlaceholderPreset | string
}
