@if(embed_class){
<div #classembed class="{{embed_class}}"></div>

    @if (showFormAlreadyFilled) {
        <small>{{'common.form_filled_before' | translate}}</small>
    }

    @if(showAcceptCookies){
    <a href="javascript: Cookiebot.renew()" class="flex flex-row justify-start gap-2">
        <small class="underline">{{'common.accept_cookies_to_show_form' | translate}}</small>
        <img src="/assets/images/cookiebot.svg" alt="cookiebot-logo" class="max-h-24 max-w-24" loading="lazy" />
    </a>
    }
}@else if(embed_url){
<iframe width="100%" height="500px" [title]="embed?.title ? embed.title : 'iframe embedded'" [src]="embed_url"></iframe>
}