<div class="flex flex-col  justify-between md:justify-start " *ngIf="!_expanded">
  <div class="flex flex-row items-center">
    <div id="ratingvalue" class="text-2xl mr-2.5" *ngIf="_rating && _rating.ratingcount > 0">{{_rating?.averageratingvalue | number:'1.1-1'}}</div>
    <ngb-rating *ngIf="show_stars" [rate]="_rating?.averageratingvalue" [max]="5" [readonly]="true">
      <ng-template let-fill="fill" let-index="index">
        <i class="bi-star{{ fill === 100 ? '-fill' : fill >= 50 ? '-half':'' }}"></i>
      </ng-template>

    </ngb-rating>
  </div>    
    <small class="mr-5" id="ratingcount" *ngIf="_rating && _rating.ratingcount > 0">{{'customerrating.average_of_x_ratings' | translate : {count:_rating?.ratingcount} }}</small>
</div>
<div *ngIf="!_rating || _rating.ratingcount === 0" class="mt-[5px]">
  <small id="noratings" class="flex items-center">
    <i class="bi bi-info-circle text-xl mr-2.5"></i><span class="ms-1"> {{'customerrating.no_ratings' | translate}}</span>
  </small>
</div>
<div class="text-center md:text-left" *ngIf="_customerrating">
  <small><i>{{'customerrating.your_rating_score' | translate}}: {{_customerrating?.ratingvalue}}</i></small>
</div>
<div class="" *ngIf="!createrating  && customer && !_customerrating" [@collapse]>
  <a class="cursor-pointer" (click)="createrating = true">
    <small>{{'customerrating.do_you_want_to_add_a_review'| translate}} <i class="bi bi-chevron-right"></i></small>
  </a>
</div>
<div class="" *ngIf="createrating && customer" [formGroup]="ratingForm" [@collapse]>
  <a class="cursor-pointer" (click)="createrating = false" *ngIf="!_expanded">
    <small><i class="bi bi-chevron-left"></i> {{'common.cancel_button'| translate}} </small>
  </a>
  <small *ngIf="!isInOrderoveriew && !isNavigatedFromOrderoveriew" id="isinorderoverview" class="flex items-center">
    <i class="bi bi-info-circle text-xl"></i><span class="ms-1">{{'customerrating.you_can_only_rate_in_orderoverview' | translate}}</span>
  </small>
  <small *ngIf="(isInOrderoveriew || isNavigatedFromOrderoveriew) && !isInOrder" id="notinorder" class="flex items-center">
    <i class="bi bi-info-circle text-xl"></i><span class="ms-1">{{'customerrating.you_can_only_rate_if_entity_is_in_order' | translate}}</span>
  </small>
  <ng-container *ngIf="(isInOrderoveriew || isNavigatedFromOrderoveriew) && isInOrder">
    <div class="" id="ratingform">
      <label class="px-0">{{'customerrating.ratingvalue' | translate}}<span class="text-danger">*</span></label>
      <ngb-rating formControlName="ratingvalue" [max]="5">
        <ng-template let-fill="fill" let-index="index">
          <i class="bi-star{{ fill === 100 ? '-fill' : fill >= 50 ? '-half':'' }}" ></i>
        </ng-template>
      </ngb-rating>
    </div>
    <div class="form-field">
      <label>{{'customerrating.ratingremarks' | translate}}</label>
      <textarea class="mb-2.5" formControlName="ratingremarks" name="ratingremarks" placeholder="{{'customerrating.ratingremarks' | translate}}"></textarea>
    </div>
    <button class="button-mid rounded-md w-full py-2"
            (click)="addRating()"
            [disabled]="!ratingForm.valid">
      {{'customerrating.add_rating' | translate}}
    </button>
  </ng-container>
</div>
