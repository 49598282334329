<div *ngIf="error_recipes" class="justify-center mt-4">
  <div class="flex flex-row text-e-orange" id="recipes_error">
    <small>{{'recipe.error_with_retrieving_recipes' | translate}}</small>
  </div>
</div>

<ng-container *ngIf="shownRecipes?.length > 0 || loading === true">

  <div #recipelist [ngClass]="container_class">
    <!-- <div class="min-w-80" [ngClass]="{'h-[480]': !condensed, 'h-[300px]': condensed}">
      <app-placeholder preset="{{condensed? 'RECIPE_CONDENSED': 'RECIPE'}}"></app-placeholder>
    </div> -->
    @if(loading && shownRecipes.length === 0){
    @for(item of [0,1,2,3,4,5,6,7,8,9]; track item){
    <div class="min-w-80" [ngClass]="{'h-[480]': !condensed, 'h-[300px]': condensed}">
      <app-placeholder preset="{{condensed? 'RECIPE_CONDENSED': 'RECIPE'}}"></app-placeholder>
    </div>
    }
    }
    <ng-container *ngFor="let recipe of shownRecipes;let i = index">
      <ng-container *ngTemplateOutlet="recipeTemplate;context:{$implicit:recipe}"></ng-container>
    </ng-container>

    <div *ngIf="loading" class="flex flex-col justify-center mb-3 mt-2">
      <h3 class="pb-3 mx-auto">{{'common.loading' | translate}}</h3>
      <app-loader class="mx-auto"></app-loader>
    </div>

  </div>

  <div class="flex flex-row justify-center" *ngIf="_deliverydate && _recipes?.length > 0 && _lifestyleids?.length > 0  && showallbtn">
    <button *ngIf=" (shownRecipes?.length < _recipes?.length)" (click)="showAllRecipes()" class="button rounded-full my-[25px] mx-auto">
      {{'recipe.show_all' | translate}}
    </button>
  </div>

  <div class="hidden md:flex" *ngIf="horizontal">
    <button title="slides naar links"
      class="flex swiper-button-prev-slide absolute top-auto left-[150px] bottom-[50px] md:bottom-auto md:left-[-65px] md:top-[calc(50%-22px)] justify-center items-center transition-opacity disabled:opacity-25"
      (click)="swipeLeft()" [disabled]="scrollPosition === 0">
      <img alt="slide-left" class="h-4 rotate-180" src="./assets/images/icons/arrow_right.svg" />
    </button>

    <button title="slides naar rechts"
      class="flex swiper-button-next-slide absolute top-auto right-[150px] bottom-[50px] md:right-[-75px] md:top-[calc(50%-22px)] justify-center items-center transition-opacity disabled:opacity-25"
      (click)="swipeRight()" [disabled]="scrollEnd">
      <img alt="slide-right" class="h-4" src="./assets/images/icons/arrow_right.svg" />
    </button>
  </div>

</ng-container>