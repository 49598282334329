import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { DatePipe, LowerCasePipe, NgFor, NgIf, formatDate, isPlatformServer } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../_environments/environment';
import { Box, BoxProduct } from '../../_model/box';
import { Boxpage } from '../../_model/boxpage';
import { Recipe } from '../../_model/recipe';
import { BoxService } from '../../_service/BoxService';
import { OrderService } from '../../_service/OrderService';
import { RecipeService } from '../../_service/RecipeService';
import { ChipComponent } from '../chip/chip.component';
import { FaqComponent } from '../faq/faq.component';
import { ImageComponent } from '../image/image.component';
import { LoaderComponent } from '../loader/loader.component';
import { RecipeComponent } from '../recipe/recipe.component';
import { RecipecardComponent } from '../recipecard/recipecard.component';
import { RecipelistComponent } from '../recipelist/recipelist.component';
import { SafeHtmlPipe } from '../pipe/safe-html.pipe';


@Component({
    selector: 'app-boxpage',
    templateUrl: './boxpage.component.html',
    styleUrls: ['./boxpage.component.scss'],
    standalone: true,
    host: { ngSkipHydration: 'true' },
    imports: [
      NgIf, NgFor,
      SafeHtmlPipe, LowerCasePipe, DatePipe,
     ImageComponent, LoaderComponent, ChipComponent, RecipelistComponent, RecipecardComponent, FaqComponent, RecipeComponent, 
     TranslateModule
    ]
})
export class BoxpageComponent implements OnInit {
  isOnServer: boolean = false;
  _env = environment;
  scrollStragy: ScrollStrategy;
  _boxpage: any; // Lifestyle or BOXPAGE
  @Input() set boxpage(value: any) {
    this.loadBoxpage(value);
  }

  loading = false;

  dateToday = formatDate(new Date(), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);
  dateNextWeek = formatDate(new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd', ɵDEFAULT_LOCALE_ID);

  persons = new FormControl(environment.personCountDefault, [Validators.min(1), Validators.max(4)]);
  meals = new FormControl(environment.mealCountDefault, [Validators.min(3), Validators.max(4)]);
  quantity = new FormControl(1, [Validators.min(1)]);

  sessiondata: any;
  sessionsteps: any;

  box: Box;
  recipes: Recipe[];

  currentUrl: string;

  dialogRef: any;

  imageRecipes: any[] = [];
  shownRecipe: Recipe;
  allergensCtrl = new FormControl([]);
  personquantity = 2;
  recipesloading = false;

  @ViewChild('recipeTmpl', { static: true }) recipedrawer;

  constructor(
    public activatedRoute: ActivatedRoute,
    private _router: Router,
    private _orderService: OrderService,
    private _boxService: BoxService,
    private _recipeService: RecipeService,
    public dialog: MatDialog,
    private _bottomSheet: MatBottomSheet,
    private readonly sso: ScrollStrategyOptions,
    public offcanvasService: NgbOffcanvas,

    @Inject(PLATFORM_ID) private platformId: any,

  ) {
    this.isOnServer = isPlatformServer(platformId)
  }

  ngOnInit() {
    this.scrollStragy = this.sso.noop();
  }

  loadBoxpage(value: Boxpage) {
    //debugger;

    this._boxpage = value;
    this.imageRecipes = [];
    this.setImageRecipes(null, value);
    this.box = null;
    if (value.boxid) {
      this.loading = true;
      this._boxService.getBoxList([value.boxid]).subscribe(
        result => {
          this.box = result[0];
          this.loading = false;
          if (this.box.boxPeriods && this.box.boxPeriods.length > 0 && this.box.boxPeriods[0].boxRecipes && this.box.boxPeriods[0].boxRecipes.length > 0) {
            this.recipesloading = true;
            this._recipeService.getRecipeList(this.box.boxPeriods[0].boxRecipes.map(r => r.recipeid)).subscribe(result => {
              this.recipesloading = false;
              this.recipes = result;
              this.setImageRecipes(result, null);
            }, error => {
              this.recipesloading = false;
            })
          }
        }, error => {
          this.loading = false;
        }
      )
    }

  }

  changePersonQty(qty: number) {
    if (!this.box) return;
    let personsindex = this.box._boxpersons.indexOf(this.persons.value);
    if (personsindex + qty <= this.box._boxpersons.length - 1 && personsindex + qty >= 0)
      this.persons.setValue(this.box._boxpersons[personsindex + qty])
  }

  changeQuantity(qty: number) {
    if ((this.quantity.value + qty) >= 1)
      this.quantity.setValue(this.quantity.value + qty)
  }

  chooseBox() {
    this._orderService.createNewOrderline(null, this.box, null, this.persons.value, this.quantity.value, false, true, false, true);
  }

  showRecipe(recipe: Recipe) {

    this.shownRecipe = recipe;
    this.offcanvasService.open(this.recipedrawer, { ariaLabelledBy: 'offcanvas-basic-title' })
  }

  setImageRecipes(recipes: Recipe[], boxpage: Boxpage) {
    if (boxpage && boxpage.websiteimageurl)
      setTimeout(() => {
        this.imageRecipes.unshift(this._boxpage);
      });

    if (!recipes) { return; }
    setTimeout(() => {

      recipes.slice(0, 3).forEach(i => {
        this.imageRecipes.push(i)
      })
      //this.imageRecipes ;
      //this.imageRecipes.unshift(this._boxpage);
    });

  }

  singleboxsellable() {
    return this.box
      && (this.box.singleboxsellablefrom && new Date(this.box.singleboxsellablefrom).getTime() <= new Date().getTime() || !this.box.singleboxsellablefrom)
      && (this.box.singleboxsellableto && new Date(this.box.singleboxsellableto).getTime() + (86400 * 1000) >= new Date().getTime() || !this.box.singleboxsellableto)
  }


  getIngredientQuantity(prod: BoxProduct) {
    let qty;
    switch (this.personquantity) {
      case 1:
        qty = prod.onepersquantity;
        break;
      case 2:
        qty = prod.twopersquantity;
        break;
      case 3:
        qty = prod.threepersquantity;
        break;
      case 4:
        qty = prod.fourpersquantity;
        break;
      default:
        qty = prod.twopersquantity;
        break;
    }
    return qty > 0 ? qty : '';
  }




}
