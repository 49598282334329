import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { CmsService } from 'src/_service/CmsService';
import { LocalstorageService } from 'src/_service/LocalstorageService';

@Component({
  selector: 'app-cms-embed',
  templateUrl: './cms-embed.component.html',
  standalone: true,
  imports: [TranslateModule]
})
export class CmsEmbedComponent implements OnInit {

  @Input() embed: any;
  embed_url: SafeResourceUrl;

  embed_class: string;
  showAcceptCookies = false
  showFormAlreadyFilled = false

  @ViewChild('classembed') classEmbedElement;


  constructor(
    private _cmsService: CmsService,
    private localstorage: LocalstorageService
  ) {
  }


  ngOnInit() {
    this.embed_class = this.embed.embed_class;
    this.embed_url = this.get_sanitized_url(this.embed.embed_url);
  }

  ngAfterViewInit() {
    const klaviyoCookie = this.localstorage.getObject("klaviyoOnsite");

    if (this.embed_class) {
      
        setTimeout(() => {
          if (klaviyoCookie && Object.keys(klaviyoCookie.viewedForms.modal.disabledForms).includes(this.embed_class.replace("klaviyo-form-", ""))){
            //form is already filled once
            this.showFormAlreadyFilled = true;
          } else {
          this.showAcceptCookies = this.classEmbedElement.nativeElement.childNodes.length === 0
          }
        }, 2000);
      
    }

  }


  get_sanitized_url(url) {
    return this._cmsService.get_sanitized_url(url);
  }

}



