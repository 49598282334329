<div *ngIf="!!card" class="card flex flex-col items-center justify-center content-normal md:flex-row md:max-w-full mx-auto rounded-lg md:rounded-l-lg overflow-x-auto mt-[30px]">
  
  @defer(on viewport){
  <recipe-list 
    [deliverydate]="card.on_the_menu === 'this_week' ? formatted_today : formatted_nextweek" 
    [pagesize]="6" 
    [horizontal]="!!card.horizontal_scroll" 
    [lifestyles]="selected_lifestyles" 
    [allergens]="selected_allergens" 
    [condensed]="card.condensed"
    class="overflow-x-auto"
    
    >

    <ng-template #recipe let-recipe>
      <recipecard class="flex items-stretch snap-center min-w-[340px]" [recipe]="recipe" [condensed]="card.condensed" [status]="'PROCESSED'" [selectedlifestyles]="selected_lifestyles" [selectedallergens]="selected_allergens" [persons]="2">
      </recipecard>
    </ng-template>
  </recipe-list>
}@placeholder{
  <div class="flex flex-row gap-6 overflow-hidden">
    @for(item of [0,1,2,3,4,5,6,7,8,9]; track item){
      <app-placeholder class="h-[480px] min-w-80" preset="RECIPE"></app-placeholder>
    }
  </div>
}
</div>