<ng-template #drawer let-offcanvas>
  <div class="bg-e-white h-full flex flex-col" id="left-drawer">
    <div class="offcanvas-header py-10 flex flex-row justify-between ">
      <div class="flex flex-col items-center">
        <img alt="Ekomenu logo" class="w-[107px] h-[23px]" [src]="env.logo" />
        <span class="font-sans font-medium text-xs text-e-dark-green tracking-widest uppercase">{{ env.subtitle | translate }}</span>
      </div>
      <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss()">
        <img src="./assets/images/icons/close.svg" height="27" width="27" />
      </button>
      
    </div>
    <div class="offcanvas-body flex flex-col justify-between">
      <div>
        <div class="flex flex-col px-3 pb-5">
          <a *ngIf="(isAuthenticated | async) ===false" class="button-base w-full rounded-full text-sm text-center py-[14px] my-[15px]" routerLink="/menus" queryParamsHandling="merge">
            <span class="text-sm font-medium ">{{ 'mpm.find_your_menu' | translate }}</span>
          </a>
          <a *ngIf="(isAuthenticated | async) ===false" class=" my-[15px]" routerLink="/login">
            <span class="text-sm font-medium text-e-dark-green">{{ 'login.sign-in' | translate }}</span>
          </a>
          <a *ngIf="(isAuthenticated | async) ===true" class="my-[15px]" routerLink="/user" queryParamsHandling="merge">
            <span class="text-sm font-medium text-e-dark-green">{{ 'user.view_or_change_order' | translate }}</span>
          </a>
          <ng-container *ngFor="let link of defaultLinks">
            <a class="my-[15px]" *ngIf="link.showInLanguage?.includes(current_lang)" routerLink="{{link.route | translate}}">
              <span class="text-sm font-medium text-e-dark-green">{{ link.label | translate }}</span>
            </a>
          </ng-container>
          <ng-container *ngIf="(isAuthenticated | async) ===true">
            <a class="my-[15px]" routerLink="/user/invite-a-friend" queryParamsHandling="merge">
              <span class="text-sm font-medium text-e-dark-green">{{ 'user.invite_a_friend' | translate }}</span>
            </a>
            <a class="my-[15px]" routerLink="/user/settings" queryParamsHandling="merge">
              <span class="text-sm font-medium text-e-dark-green">{{ 'user.my_settings' | translate }}</span>
            </a>
            <a class="my-[15px] cursor-pointer" (click)="logout()">
              <span class="text-sm font-medium text-e-dark-green">{{ 'login.logout' | translate }}</span>
            </a>
          </ng-container>
        </div>
      </div>

      <div class="form-field" id="languageswitch" *ngIf="(isAuthenticated.value) ===false">
        <label>{{'customer.preferredlanguage' | translate}}</label>
        <select [formControl]="preferredlanguageForm">
          <option *ngFor="let code of languages" [value]="code">
            {{'language.' + code | translate}}
          </option>
        </select>
      </div>
    </div>
  </div>
</ng-template>
