<div class="bg-white">
  <div class="bg-e-white md:py-[50px] rounded-xl">
    <div class="container-default flex flex-col-reverse md:flex-row md:justify-between px-[25px]">
      <div class="flex flex-col items-start justify-center md:w-1/3 md:mr-[25px]">
        <h1 class="" itemprop="name">{{_box?.title || _box?.name}}</h1>
        <h4 class="">{{_box?.subtitle}}</h4>
        <div class="my-5" [innerHTML]="_box?.shortdescription | safeHtml"></div>

        <ng-container *ngIf="(qtyeditable || repeatableeditable) && getBoxCountrypersonsprice()">
          <div *ngIf="(getBoxCountrypersonsprice()?.boxdiscountpercentage > 0 || getBoxCountrypersonsprice()?.boxdiscountamount > 0 )" class="bg-e-gold text-white rounded-full px-2">
            <small>
              <ng-container *ngIf="getBoxCountrypersonsprice().boxdiscountpercentage; else discountprice">{{calculatedPrice?.calculateddiscountpercentage}}% {{'common.discount' | translate}}</ng-container>
              <ng-template #discountprice>{{calculatedPrice?.discountincvat |currency: 'EUR':'symbol':'.2-2':'nl'}} {{'common.discount' | translate}}</ng-template>
            </small>
          </div>
          <pricetag class="p-0 col-6" [productprice]="true" [calculatedPrice]="calculatedPrice"></pricetag>
          <small *ngIf="!repeatable.value && deliverydate" class="text-secondary">{{'subscription.delivery_of' | translate}} {{deliverydate | localizedDate :'EEEE d MMMM' |lowercase}}</small>

          <small *ngIf="_box?.singleboxsellablefrom || _box?.singleboxsellableto">{{'box.deliverable_between'| translate: {from: _box?.boxPeriods[0]?.activefrom |localizedDate: 'd MMMM yyyy', to: _box?.boxPeriods[0]?.activeto|localizedDate: 'd MMMM yyyy' } }}</small>
          <!--<small *ngIf="_box?.singleboxsellablefrom && _box.singleboxsellableto">{{'box.sellable_between'| translate: {from: _box?.singleboxsellablefrom |localizedDate: 'd MMMM yyyy', to: _box.singleboxsellableto|localizedDate: 'd MMMM yyyy' } }}</small>
  <small *ngIf="_box?.singleboxsellablefrom && !_box.singleboxsellableto">{{'box.sellable_from'| translate: {from: _box?.singleboxsellablefrom |localizedDate: 'd MMMM yyyy'} }}</small>
  <small *ngIf="!_box?.singleboxsellablefrom && _box.singleboxsellableto">{{'box.sellable_to'| translate: {to: _box?.singleboxsellableto |localizedDate: 'd MMMM yyyy'} }}</small>-->

        <div class="flex w-full justify-between mt-[25px]">
          <span class="my-auto me-2">{{'common.quantity' | translate}}</span>
          <app-quantityselection class="w-2/3" [quantity]="quantity.value" (quantityChange)="setQuantity($event)" [contrastColorscheme]="colorscheme"></app-quantityselection>
        </div>
        <div class="flex w-full justify-between mt-[25px]" *ngIf="personseditable">
          <span class="my-auto me-2">{{'common.person' | translate}}</span>
          <app-personselection class="w-2/3"  [contrastColorscheme]="colorscheme" [persons]="persons.value" (personsChange)="setPersonQuantity($event)" [personsoptions]="_box?._boxpersons" [doublepersons]="_box?.doublepersons">
            <!--<span>{{'common.person_abbr'|translate}}</span>-->
          </app-personselection>
        </div>
          <app-toggle *ngIf="repeatableeditable" class="ms-2 mt-1" [formControl]="repeatable" color="success">{{'subscription.product_weekly' | translate}}</app-toggle>
        </ng-container>
        <div *ngIf="!qtyeditable && quantity.value !== 0">
          <small>{{'common.piece' |translate : {count: quantity.value}  }}</small>
        </div>
        <div class="mt-3" *ngIf="!personseditable ">
          <small>{{_box?.doublepersons? persons.value * 2 : persons.value}} {{'common.person'|translate}}</small>
        </div>
      </div>

      <div class="md:w-2/3">
        <app-image [imageobject]="_box" size="lg" alt="{{_box?.title}}" class="rounded-3xl object-cover w-full"></app-image>
      </div>
    </div>
    <div class="hidden md:flex w-[39px] h-[39px] icon-round mx-auto mt-[25px]">
      <img _ngcontent-serverapp-c211="" src="./assets/images/icons/arrow_down.svg" class="h-5 w-5">
    </div>
  </div>
  <div class="container-default flex flex-col px-[25px] mt-[25px]">
    <div class="w-full mt-[25px]" [innerHTML]="_box?.description | safeHtml"></div>

    <h2 class="mx-2 mt-[25px]">{{'box.content' | translate}}</h2>
    <div class="flex flex-row flex-wrap" *ngIf="_box?.boxPeriods[0]?.boxProducts.length>0">
      <ng-container *ngFor="let i of _env.subscriptionPersonChoices">
        <app-chip (click)="personquantity = i" [selected]="personquantity === i" colorscheme="contrast"><span>{{i}} {{'common.person_abbr' | translate}}</span></app-chip>
      </ng-container>
    </div>

    <ul class="flex flex-col" *ngIf="_box?.boxPeriods && _box?.boxPeriods.length>0">
      <ng-container *ngFor="let product of _box?.boxPeriods[0].boxProducts">
        <li class="py-1">
          <strong>{{getIngredientQuantity(product) || 0}} {{'unit_abbr.' + product.unit | translate | lowercase}} {{product.displayname}}</strong>
          <small class="block" *ngIf="product.producer"><i>{{'common.from_posessive'| translate}} {{product.producer}}</i></small>
        </li>
      </ng-container>
    </ul>

    <div class="recipes flex flex-row flex-wrap mt-3 m-2 gx-0 bg-light" *ngIf="_box?.boxPeriods && _box?.boxPeriods.length>0">
      <recipe-list [recipes]="recipes" class="w-full">
        <ng-template #recipe let-recipe>
          <recipecard [recipe]="recipe" colorscheme="light" >          </recipecard>
        </ng-template>
      </recipe-list>
    </div>
  </div>



</div>
