<div class="flex flex-col items-center rounded-lg bg-white md:items-stretch" [ngClass]="index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse' ">
  <div class="flex w-full flex-col justify-between" [ngClass]="extendable ? 'p-[10px_15px]' : 'p-[25px]'">
    <!-- <app-image size="md" [imageobject]="_lifestyle" alt="{{_lifestyle?.title}}" class="w-100 cursor-pointer"></app-image> -->


    <div class="flex flex-col" (click)="extendable = !extendable">
      <div class="flex cursor-pointer flex-row items-center" [ngClass]="extendable ? 'justify-between' : 'mb-5'">
        <img [src]='"./assets/images/icons/lifestyle/icon_leefstijl_" + _lifestyle?.id + ".svg"' height="50" width="72" [ngClass]="extendable ? 'h-10' : 'h-[50px]'" />
        <h3 class="text-e-dark-green mb-0 font-sans" [ngClass]="extendable ? 'text-base font-bold' : 'text-xl font-medium ml-[25px]'">{{_lifestyle?.title}}</h3>
        <img *ngIf="extendable" class="h-[14px]" src="./assets/images/icons/arrow_right.svg" />
      </div>
      <div *ngIf="!extendable" class="my-2 min-h-28" [innerHTML]="_lifestyle?.shortdescription | safeHtml"></div>
    </div>
    <div *ngIf="!extendable " > <!-- @collapse => removed this because it didnt update correctly when navigating forward and backward in the orderflow -->
        <div class="my-5 flex flex-row items-center">
          <small class="w-1/3 font-bold">{{'common.person' | translate}}</small>
          <div class="bg-e-white h-[35px] flex w-2/3 rounded-full">
            <button class="h-[35px] w-1/4 disabled:opacity-25" [disabled]="persons.value === _globalService.minSubscriptionPersons" (click)="changePersonQty(-1)">
              <i class="bi bi-dash leading-[35px] text-xl"></i>
            </button>
            <small class="leading-[35px] inline-block w-2/4 text-center">{{persons.value}}</small>
            <button class="w-1/4 disabled:opacity-25" [disabled]="persons.value === _globalService.maxSubscriptionPersons" (click)="changePersonQty(1)">
              <i class="bi bi-plus leading-[35px] text-xl"></i>
            </button>
          </div>
        </div>
        <div class="mb-5 flex flex-row items-center">
          <small class="w-1/3 font-bold">{{'common.meal' | translate}}</small>
          <div class="bg-e-white h-[35px] flex w-2/3 rounded-full">
            <button class="w-1/4 disabled:opacity-25" [disabled]="meals.value === _globalService.minSubscriptionMeals" (click)="changeMealQty(-1)">
              <i class="bi bi-dash leading-[35px] text-xl"></i>
            </button>
            <small class="leading-[35px] inline-block w-2/4 text-center">{{meals.value}}</small>
            <button class="w-1/4 disabled:opacity-25" [disabled]="meals.value === _globalService.maxSubscriptionMeals" (click)="changeMealQty(1)">
              <i class="bi bi-plus leading-[35px] text-xl"></i>
            </button>
          </div>
        </div>

        <div class="mt-2 flex flex-row xl:flex-row">
          <ng-container *ngIf="!disableShowAction; else buttonNavigation">
            <button (click)="showLifestyleAction()" class="button rounded-ld w-full hover:bg-e-reg-green hover:text-e-dark-green">
              {{'lifestyle.chosen' | translate}}
              <i class="bi bi-check2"></i>
            </button>
            <button (click)="showLifestyleAction()" *ngIf="flowtype === 'PERSONALMENU'" class="button-clear mx-3 my-auto whitespace-nowrap rounded-lg">
              {{'lifestyle.more_info' | translate}}
            </button>
          </ng-container>
          <ng-template #buttonNavigation>
            <a (click)="chooseLifestyle()" class="button-base w-full rounded-lg" [ngClass]="{'bg-e-reg-green text-e-dark-green': selected}">
              <ng-container *ngIf="!selected">
                {{'lifestyle.choose' | translate}}
                {{_lifestyle?.title | lowercase}}
              </ng-container>
              <ng-container *ngIf="selected">
                {{'lifestyle.chosen' | translate}}
                <i class="bi bi-check2"></i>
              </ng-container>
            </a>
            <a [routerLink]="[_lifestyle['lifestyleurl']]" (click)="setLifestyle()" *ngIf="flowtype === 'PERSONALMENU'" class="button-clear mx-3 my-auto whitespace-nowrap rounded-lg">
              {{'lifestyle.more_info' | translate}}
            </a>
          </ng-template>
        </div>
    </div>
  </div>

</div>

<lifestylechosendialog #lifestylechosendialog></lifestylechosendialog>
