import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { FormArray, FormGroup } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/_environments/environment';
import { User } from 'src/_model/user';
import { AuthService } from 'src/_service/AuthService';
import { DrawerService } from 'src/_service/DrawerService';
import { OrderService } from '../../_service/OrderService';
import { clickAnimation } from '../../_theme/animations';


@Component({
    selector: 'topmenu',
    templateUrl: './topmenu.component.html',
    animations: [clickAnimation],
    standalone: true,
    imports: [NgIf, RouterLink, NgFor, RouterLinkActive, NgClass, AsyncPipe, TranslateModule]
})

export class TopMenuComponent implements OnInit, OnDestroy {

  env = { ...environment };
  show_userbuttons = true;
  routeEventSubscription: any;
  currentUser: User = null;

  lastCartquantity: number;
  scrollStrategy: ScrollStrategy;

  is_in_cart = false;
  clickanimation = false;
  get orderlines(): FormArray {
    return this._orderService.orderlines as FormArray;
  }
  get order(): FormGroup {
    return this._orderService.order as FormGroup;
  }

  unsubscribe = new Subject<void>();
  isAuthenticated : BehaviorSubject<boolean>  =new BehaviorSubject(false);

  constructor(
    private _authService: AuthService,
    public router: Router,
    private _orderService: OrderService,
    private drawerService: DrawerService,
    private readonly sso: ScrollStrategyOptions,
    private _cd: ChangeDetectorRef,
  ) {
    this.isAuthenticated = this._authService.isAuthenticatedSubject;
  }
 
  ngOnInit() {
    this.routeEventSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event['urlAfterRedirects'].split(/[\/\?]/);
        const url_start = url[1];
        
        if ((url_start === 'personalmenu') && (url[2] !== 'lifestyles')) {
          this.show_userbuttons = false;
        } else {
          this.show_userbuttons = true;
        }

        if (url_start === 'checkout' && url[2] !== 'webshop') {
          this.is_in_cart = true
        } else {
          this.is_in_cart = false;
        }
      }
    });
    
    this.scrollStrategy = this.sso.noop();
    // listen to this here to make sure we set current user after we have it.
    this.isAuthenticated.subscribe(
      (data) => {
        if (data) {
          this.currentUser = this._authService.getCurrentUser();
        } else {
          this.currentUser = null;
        }
        this._cd.detectChanges();
      }
    )
  }

  ngOnDestroy() {
    this.routeEventSubscription.unsubscribe();
    this.unsubscribe.next();
  }

  toggleDrawer() {
    this.drawerService.toggle('left-drawer');
  }

  orderlineTotalQuantity() {
    const qtys = this.orderlines?.value.map(l => l.quantity) as number[];
    let qty = qtys.reduce((a, b) => a + b, 0)

    if (qty != this.lastCartquantity) {
      this.lastCartquantity = qty;
      this.clickanimation = true;
      setTimeout(() => {
        this.clickanimation = false;
      },500)
    }
    return qty
  }

  removeOrderline(index: number) {
    this._orderService.removeOrderlineByIndex(index);
  }


}
