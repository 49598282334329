import { formatDate, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, ɵDEFAULT_LOCALE_ID } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Router, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, pairwise } from 'rxjs';
import { environment } from '../_environments/_environment';
import { Activepricecountry, Countrycode, Customercountrycode, Language, Preferredlanguage, Sourcetype } from '../_model/shared';
import { Weekday } from '../_model/weeks';
import { WINDOW } from '../_service/WindowService';
import { LocalstorageService } from './LocalstorageService';
import { CustomerCreateOrderLine, CustomerCreateOrderRequest } from '../_model/pricing';
import { Recipe } from '../_model/recipe';
import { Category, Product } from '../_model/product';
import { Box } from '../_model/box';
import { Customer } from '../_model/customer';
import { SessionstorageService } from './SessionstorageService';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
export class GlobalService {

  klaviyoTrackingEvents = [];
  klaviyoSessionid = null;
  trackingClient: any;

  isPrerendered = false;
  isInBrowser = false;

  minSubscriptionPersons: number = Math.min.apply(null, environment.subscriptionPersonChoices);
  maxSubscriptionPersons: number = Math.max.apply(null, environment.subscriptionPersonChoices);
  minSubscriptionMeals: number = Math.min.apply(null, environment.subscriptionRecipeChoices);
  maxSubscriptionMeals: number = Math.max.apply(null, environment.subscriptionRecipeChoices);

  private previousUrl: string;
  private previousUrls: string[] = [];
  private currentUrl: string;

  constructor(
    public translate: TranslateService,
    private _router: Router,
    private _titleService: Title,
    private _cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: Window,
    private _localstorage: LocalstorageService,
    private _sessionstorage: SessionstorageService
  ) {

    if (isPlatformBrowser(this.platformId)) {
      this.isInBrowser = true;
      if (document.getElementById('staticpagecontentloaded')) {
        this.isPrerendered = true;
      }

      this.removeKlaviyoIdCookie();
    }



    let countrycodes = this.getCustomercountrycodes();
    if (isPlatformBrowser(this.platformId)) {
      const tld = this.getTld();
      const tldCountrycode = countrycodes.filter(c => c.toLowerCase() === tld.toLowerCase())[0];

      if (tldCountrycode) {
        this.setPricecountry(Sourcetype.URL, Countrycode[tldCountrycode])
      }
    }

    _router.events.pipe(
      filter((evt: any) => evt instanceof RoutesRecognized)
      , pairwise()
    ).subscribe((event: RoutesRecognized[]) => {
      this.previousUrl = event[0].url;
      this.previousUrls.push(event[0].url);
      this.currentUrl = event[1].url;
    });

  }

  removeKlaviyoIdCookie(){
    if (this._localstorage.getObject('removedKlaviyoIdCookieAt') !== null) return;
    Object.keys(this._cookieService.getAll()).forEach(key => {
      if (key === '__kla_id') {
        this._cookieService.delete(key);
      }
    })
    this._localstorage.setObject('removedKlaviyoIdCookieAt', new Date());
  }


  activeLanguage: Preferredlanguage;
  activePricecountry: Activepricecountry;
  // sets the language for everything
  getLanguage(): string {
    let pref;

    if (!this.activeLanguage) {
      if (this.isInBrowser)
        pref = this._localstorage.getObject('preferredlanguage') as Preferredlanguage;
      if (pref) this.setLanguage(pref.languagetype, pref.language);
    } else {
      pref = this?.activeLanguage;
    }

    if (!pref) {
      let lang = Language.NL;
      if (!!this.translate) {
        let browserlang = this.translate.getBrowserLang()
        if (!!browserlang && Language[browserlang.toUpperCase()])
          lang = Language[browserlang.toUpperCase()];
      }
      this.setLanguage(Sourcetype.BROWSER, lang);
      return lang.toLowerCase();
    } else {
      return pref.language.toLowerCase();
    }
  }
  setLanguage(languagetype: Sourcetype, language: Language) {
    if (!language) { return; }
    let pref;
    let lang_changed = false;

    if (!this.activeLanguage) {
      if (this.isInBrowser)
        pref = this._localstorage.getObject('preferredlanguage') as Preferredlanguage;
      if (!!pref) this.activeLanguage = pref;
    } else {
      pref = this.activeLanguage;
    }

    if (pref) {
      const types = Object.keys(Sourcetype);
      //only change if languagetype is customer
      if (languagetype == Sourcetype.CUSTOMER) {
        pref = new Preferredlanguage(languagetype, language);
        lang_changed = true;
      } else if (types.indexOf(pref.languagetype) < types.indexOf(languagetype)) {
        pref = new Preferredlanguage(languagetype, language);
        lang_changed = true;
      }
    } else {
      pref = new Preferredlanguage(languagetype, language);
      lang_changed = true;
    }
    if (lang_changed) {
      this.activeLanguage = pref; // don't forget to remember the new pref ! 
      if (this.isInBrowser) {
        this._localstorage.setObject('preferredlanguage', pref);
      }
      //gets the available translations
      const langs = this.translate.langs.join("|");
      const rex = new RegExp(langs);
      //checks if the translation is available and otherwise uses english
      this.translate.use(language.toLowerCase().match(rex) ? language.toLowerCase() : 'en');
    }

    // push lang to the data layer
    if (this.isInBrowser)
      try {
        // these calls will fail in non-browser versions, but we should not care about this there anyway
        this.window['dataLayer'].push({ lang: pref.language });
        this.window['dataLayer'].push({ langtype: pref.languagetype }); // TODO: do we need this info ?\
      } catch (e) {
        // console.warn('datalayer access failed:');
        // console.warn(e);
      }
  }
  getPricecountry(): string {
    let pref: Activepricecountry;
    if (!this.activePricecountry) {
      if (this.isInBrowser)
        pref = this._localstorage.getObject('pricecountry') as Activepricecountry;
      if (pref) this.activePricecountry = pref;
    } else {
      pref = this.activePricecountry;
    }

    if (!pref) {
      return Countrycode.NL;
    } else {
      return pref.pricecountry.toUpperCase();
    }

  }
  setPricecountry(pricecountryType: Sourcetype, country: Countrycode) {
    if (!pricecountryType || !country) return;
    let pref: Activepricecountry;
    if (!this.activePricecountry) {
      if (this.isInBrowser)
        pref = this._localstorage.getObject('pricecountry') as Activepricecountry;
      this.activePricecountry = pref;
    } else {
      pref = this.activePricecountry;
    }
    const types = Object.keys(Sourcetype);

    if (pref) {
      if (types.indexOf(pref.pricecountrytype) <= types.indexOf(pricecountryType)) {
        pref = new Activepricecountry(pricecountryType, country);
        if (this.isInBrowser)
          this._localstorage.setObject('pricecountry', pref)
      }
    } else {
      pref = new Activepricecountry(pricecountryType, country);
      if (this.isInBrowser)
        this._localstorage.setObject('pricecountry', pref)
    }
  }

  getTld(): string {
    const baseUrl = !!this.window && !!this.window.location && !this.window.location.origin.includes('localhost') ? this.window.location.origin : 'www.ekomenu.nl';
    const tld = baseUrl.slice(baseUrl.length - 2, baseUrl.length);
    return tld;
  }

  setTranslatedTitle(key_str) {
    this.translate.get(key_str).subscribe(translated_str => {
      this.setTitle(translated_str)
    });
  }
  setTitle(string: string) {
    let complete_title = '';
    if (environment.title_prefix) {
      complete_title += environment.title_prefix + ' ';
    }
    if (string) {
      complete_title += string;
    }
    if (environment.title_postfix) {
      complete_title += (complete_title ? ' | ' : '') + environment.title_postfix;
    }
    this._titleService.setTitle(complete_title);
  }

  getLanguagecodes(): string[] {
    return Object.keys(Language);
  }
  getCustomercountrycodes(): string[] {
    return Object.keys(Customercountrycode);
  }
  getWeekdays(): string[] {
    return Object.keys(Weekday);

  }

  getPreviousUrl(): string {
    return this.previousUrl || '';
  }
  getPreviousUrls(): string[] {
    return this.previousUrls || [];
  }

  addDays(date: Date, days: number): Date {
    let newdate = new Date(date.setDate(date.getDate() + days))
    return newdate;
  }

  getDirtyValues(form: FormGroup | FormArray,) {
    let dirtyValues = {};
    if (form instanceof FormArray)
      dirtyValues = [];

    Object.keys(form.controls).forEach(key => {
      const currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls) {
          dirtyValues[key] = this.getDirtyValues(currentControl);
          //if object is dirty, but not underlying properties and thus only contains undefined values
          if (Object.keys(dirtyValues[key]).filter(k => { return dirtyValues[key][k] !== undefined }).length === 0) {
            dirtyValues[key] = currentControl.value
          }
        }
        else {
          dirtyValues[key] = currentControl.value;
        }
      } else {
        dirtyValues[key] = undefined;
      }
    });

    //console.log('dirtyvalues', dirtyValues)
    return dirtyValues;
  }
  mergeDirtyValues(changedData: any, newData: any) {
    Object.keys(changedData).forEach(key => {
      const currentControl = changedData[key];
      if (currentControl !== undefined) {
        //if object has no undefined controls, it has to be a full object and it can be totally overwritten
        if (currentControl && (Object.keys(currentControl).length === 0 || Object.keys(currentControl).filter(k => { return currentControl[k] !== undefined }).length === 0)) {
          newData[key] = undefined;
        }

        if (Array.isArray(currentControl) && newData[key] !== undefined) {
          //sets currentcontrol when element is removed. 
          if (currentControl.length < newData[key].length)
            newData[key] = currentControl;
          else {
            newData[key] = this.mergeDirtyValues(currentControl, newData[key]);
          }
        }
        //if value is set to null, it is just set on the newdata, null is seen as a object
        else if (currentControl && typeof (currentControl) === 'object' && newData[key] !== undefined) {
          newData[key] = this.mergeDirtyValues(currentControl, newData[key]);
        }
        else //if (newData[key] !== undefined)
          newData[key] = currentControl;
      }
    });

    return newData;
  }
  setValueOnChanges(form: FormGroup, controlname: string, fromForm: FormGroup, fromControlname: string = null) {
    fromControlname = fromControlname ? fromControlname : controlname;
    if (!fromForm.get(fromControlname)) {
      console.log(`formcontrol with name ${fromControlname} is not found`);
      return;
    }

    fromForm.get(fromControlname).valueChanges.subscribe(res => {
      form.get(controlname).setValue(res)
      form.get(controlname).markAsDirty()
    })
    form.get(controlname).setValue(fromForm.get(fromControlname).value)
    form.get(controlname).markAsDirty()

  }

  replaceControl(form: FormGroup, controlname: string, fromForm: FormGroup, fromControlname: string = null) {
    fromControlname = fromControlname ? fromControlname : controlname;
    form.removeControl(controlname)
    form.addControl(controlname, fromForm.get(fromControlname))

  }

  makeUnique<T>(object: T): T {
    return JSON.parse(JSON.stringify(object)) as T
  }

  periodsOverlap(first: { from: string, to: string }, second: { from: string, to: string }): boolean {
    const firstfrom = new Date(first.from).getTime()
    const firstto = new Date(first.to).getTime()
    const secondfrom = new Date(second.from).getTime()
    const secondto = new Date(second.to).getTime()


    if (firstfrom >= secondfrom && firstto <= secondto || firstfrom <= secondfrom && firstto >= secondto) {
      //testing (both ways):
      //   | |
      //|       |arguments
      return true;

    } else if (firstfrom >= secondfrom && firstfrom <= secondto) {
      //testing:
      // |     |
      //     |       |
      return true;

    } else if (firstto >= secondfrom && firstto <= secondto) {
      //testing:
      //    |     |
      //|       |
      return true;
    } else {
      return false;
    }
  }


  trackProductViewed(product: Recipe | Box | Product, price: number = 0) {
    this.addToDatalayer('view_item', {
      ...{ currency: 'EUR', value: price || 0 },
      ...this.createDatalayerItem(product)
    }
    )
    this.addToKlaviyo("Viewed Product", this.createKlaviyoItem(product, price));
  }

  trackAddToCart(orderlines: CustomerCreateOrderLine[], orderline: CustomerCreateOrderLine, price: number = 0, ordertotal: number = 0) {
    this.addToDatalayer('add_to_cart', {
      ...{ currency: 'EUR', value: price || 0 },
      ...this.createDatalayerItemlist([orderline])
    });

    const added_orderline = this.createKlaviyoOrderline(orderline, price);
    const klaviyo_orderlines = orderlines.map(o => this.createKlaviyoOrderline(o))

    this.addToKlaviyo("Added to Cart", {
      $value: ordertotal,
      AddedItemProductName: added_orderline.ProductName,
      AddedItemProductID: added_orderline.ProductID,
      AddedItemPrice: added_orderline.Price,
      AddedItemQuantity: added_orderline.Quantity,
      AddedItemCategories: added_orderline.Categories,

      ItemNames: klaviyo_orderlines.map(n => n.ProductName),
      Items: klaviyo_orderlines
    })
  }
  trackRemoveFromCart(orderlines: CustomerCreateOrderLine[], orderline: CustomerCreateOrderLine, price: number = 0, ordertotal: number = 0) {
    this.addToDatalayer('remove_from_cart', {
      ...{ currency: 'EUR', value: price || 0 },
      ...this.createDatalayerItemlist([orderline])
    });

    const added_orderline = this.createKlaviyoOrderline(orderline, price);
    const klaviyo_orderlines = orderlines.map(o => this.createKlaviyoOrderline(o))

    this.addToKlaviyo("Removed from Cart", {
      $value: ordertotal,
      AddedItemProductName: added_orderline.ProductName,
      AddedItemProductID: added_orderline.ProductID,
      AddedItemPrice: added_orderline.Price,
      AddedItemQuantity: added_orderline.Quantity,
      AddedItemCategories: added_orderline.Categories,

      ItemNames: klaviyo_orderlines.map(n => n.ProductName),
      Items: klaviyo_orderlines
    })
  }

  trackBeginCheckout(order: CustomerCreateOrderRequest, orderlines: CustomerCreateOrderLine[], customer: Customer) {
    this.addToDatalayer('begin_checkout',
      {
        ...this.createDatalayerItemlist(orderlines),
        ...this.createDatalayerOrderdata(order, customer)
      }
    )
    if (!this.klaviyoSessionid) {
      this.klaviyoSessionid = this._sessionstorage.getItem('klaviyoSessionid')
      if (!this.klaviyoSessionid) {
        this.klaviyoSessionid = new Date().getTime();
        this._sessionstorage.setItem('klaviyoSessionid', this.klaviyoSessionid.toString())
      }
    }

    const klaviyo_orderlines = orderlines.map(o => this.createKlaviyoOrderline(o))

    this.addToKlaviyo("Started Checkout", {
      $event_id: this.klaviyoSessionid,
      $value: order?.calculatedPrice?.priceexvat || 0,
      ItemNames: klaviyo_orderlines.map(n => n.ProductName),
      Categories: klaviyo_orderlines.flatMap(c => c.Categories),
      Items: klaviyo_orderlines
    })
  }

  addToKlaviyo(eventName: string, object: Object = null) {
    try {
      // this.window['klaviyo'].push(['track', eventName, object])
      if (!this.window['klaviyo']) return;

      this.window['klaviyo'].isIdentified((identified) => {
        if (identified) {
          this.window['klaviyo'].track(eventName, object)
        } else {
          object['time'] = formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss', ɵDEFAULT_LOCALE_ID); new Date();
          this.klaviyoTrackingEvents.push(['track', eventName, object])
        }
      })
      // this.window['klaviyo'].track(eventName, object)

    } catch (e) {
      console.warn('klaviyo access failed:');
      console.warn(e);
    }
  }

  identifyKlaviyo(customer: Customer) {
    try {
      // this.window['klaviyo'].push(['track', eventName, object])
      this.window['klaviyo'].identify({
        email: customer.email,
        first_name: customer.firstname,
        last_name: (customer.prefix + ' ' || '') + (customer.lastname || ''),
        phone_number: customer.phonenumber
      }).then(obj => {
        console.log('Identify has been completed')
        this.klaviyoTrackingEvents.forEach(evt => {
          this.window['klaviyo'].push(evt)
        })
        this.klaviyoTrackingEvents = [];
      });
    } catch (e) {
      console.warn('klaviyo identify access failed:');
      console.warn(e);
    }
  }

  createKlaviyoItem(item: Recipe | Box | Product, price: number): any {
    return {
      ProductName: item['displayname'] || item['name'],
      ProductID: item['cookingtime'] ? `recipe-${item.id}` : item['associatedlifestyle'] ? `box-${item.id}` : item['sku'],
      Categories: item['cookingtime'] ? `recipe` : item['associatedlifestyle'] ? `box` : `product`,
      ImageURL: item.websiteimageurl,
      Price: price,
    }
  }
  createKlaviyoOrderline(line: CustomerCreateOrderLine, price: number = null): any {
    return {
      ProductName: line.description,
      ProductID: line.recipeid ? `recipe-${line.recipeid}` : line.boxid ? `box-${line.boxid}` : line.sku,
      Quantity: line.quantity,
      Persons: line.persons,
      Categories: [line.recipeid ? `recipe` : line.boxid ? `box` : `product`],
      ImageURL: line['object']?.websiteimageurl,
      Price: price || line?.calculatedPrice?.priceexvat || 0,
      RowTotal: line?.calculatedPrice?.priceexvat || 0
    }
  }


  addToDatalayer(eventName: string, object: Object = null) {
    try {
      let event = { event: eventName }
      if (object)
        event['ecommerce'] = object;

      this.window['dataLayer'].push(event);
    } catch (e) {
      console.warn('datalayer access failed:');
      console.warn(e);
    }
  }

  createDatalayerItemlist(orderlines: CustomerCreateOrderLine[]): any {
    const products = orderlines.map((line, index) => ({
      item_id: line.recipeid ? `recipe-${line.recipeid}` : line.boxid ? `box-${line.boxid}` : line.sku,
      item_name: line.description,
      index: index,
      item_category: line.recipeid ? `recipe` : line.boxid ? `box` : `product`,
      coupon: `${line.couponcodeitem}`,
      discount: line.calculatedPrice?.discountexvat,
      price: line.calculatedPrice?.priceexvat,
      quantity: line.quantity,
      item_variant: line.recipeid || line.boxid ? `${line.persons}-persons` : null
    }));

    return { items: products }
  }
  createDatalayerItem(item: Recipe | Box | Product): any {
    return {
      items: [{
        item_id: item['cookingtime'] ? `recipe-${item.id}` : item['associatedlifestyle'] ? `box-${item.id}` : item['sku'],
        item_name: item['displayname'] || item['name'],
        item_category: item['cookingtime'] ? `recipe` : item['associatedlifestyle'] ? `box` : `product`,
      }]
    }
  }
  createDatalayerOrderdata(order: CustomerCreateOrderRequest, customer: Customer): any {
    const value = {
      currency: 'EUR',
      value: order.calculatedPrice?.priceexvat - (order.calculatedPrice?.shippingcost - order.calculatedPrice?.shippingcostvat) || 0,
      coupon: order.couponcode,
      payment_type: order.paymentmethod,
      shipping_tier: order.shippingmethod,
      lifestyleid: customer.customerLifestyles[0]?.lifestyleid || null
    }
    return value;
  }



}
