import { Component, Inject, Input, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Popup, PopupAudience, PopupCloseConfig } from '../../_model/storyblock';
import { AuthService } from '../../_service/AuthService';
import { CmsService } from '../../_service/CmsService';
import { WINDOW } from '../../_service/WindowService';
import { NgbModalDefaultOptions } from '../../_theme/snackbarformats';
import { CmsSectionComponent } from '../cms-section/cms-section.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CmsDirective } from '../../appModule/cms.directive';
import { LocalstorageService } from 'src/_service/LocalstorageService';
import { Subject, takeUntil } from 'rxjs';
import { environment } from 'src/_environments/environment';

@Component({
  selector: 'app-cms-popup',
  standalone: true,
  templateUrl: './cms-popup.component.html',
  styleUrl: './cms-popup.component.scss',
  imports: [
    NgIf, NgFor, NgClass,
    CmsDirective,
    CmsSectionComponent
  ],
  host: { ngSkipHydration: 'true' },
})
export class CmsPopupComponent implements OnInit {

  dismissedPopups: { id: string, dismissedAt: Date }[] = [];

  popupTitle: any;
  popupOptions = NgbModalDefaultOptions;
  popupModal: NgbModalRef;

  backgroundColor = "bg-white";

  private _popup: Popup
  @Input() set popup(value: Popup) {
    this._popup = value;
    if (value.title)
      this.popupTitle = this._cmsService.render_rt(value.title)

    this.popupOptions.size = value.size;
    this.backgroundColor = value.bgcolor ? 'bg-' + value.bgcolor : "bg-white"
  }
  get popup() { return this._popup }

  private scrollListener: () => void = () => { };
  private maxScroll = 0;

  @ViewChild('modalcontent', { read: TemplateRef }) modalContent: TemplateRef<any>;

  private _unsubscribe = new Subject<void>();

  constructor(
    private renderer2: Renderer2,
    private _authService: AuthService,
    private _cmsService: CmsService,
    private _modalService: NgbModal,
    private _localstorage: LocalstorageService,
    @Inject(WINDOW) private window: Window,
  ) { }

  ngOnInit() {

    this.dismissedPopups = this._localstorage.getObject("dismissedpopups") || [];

    if (
      this.popup &&
      (
        (this._authService.isAuthenticatedSubject.value && this.popup.audience === PopupAudience.CUSTOMERS)
        || (!this._authService.isAuthenticatedSubject.value && this.popup.audience === PopupAudience.NON_CUSTOMERS)
        || this.popup.audience === PopupAudience.EVERYONE
      )

    ) {
      this.scrollListener = this.renderer2.listen("document", "scroll", event => {

        this.maxScroll = this.window.scrollY > this.maxScroll ? this.window.scrollY : this.maxScroll;

        if (this.maxScroll - this.window.scrollY > 250) {
          //makes sure that the modal isnt dismissed before
          if (this.dismissedPopups.map(f => f.id).includes(this.popup._uid)) {
            this.scrollListener();
            return;
          }
          //makes sure only 1 modal is added
          if (this.popupModal) { return; }

          if (this.popup.closeconfig === PopupCloseConfig.CLOSEBUTTON)
            this.popupOptions.beforeDismiss = () => { return false; }

          this.popupModal = this._modalService.open(this.modalContent, this.popupOptions)
          this.popupModal.closed.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
            this.dismissedPopups.push({ id: this.popup._uid, dismissedAt: new Date() })
            if (environment.production)
              this._localstorage.setObject("dismissedpopups", this.dismissedPopups)
          })
          this.popupModal.dismissed.pipe(takeUntil(this._unsubscribe)).subscribe(() => {
            this.dismissedPopups.push({ id: this.popup._uid, dismissedAt: new Date() })
            if (environment.production)
              this._localstorage.setObject("dismissedpopups", this.dismissedPopups)
          })
          if (environment.production)
            this.scrollListener();
        }
      })
    }

  }

  ngOnDestroy() {
    this.scrollListener();
    this._unsubscribe.next();
  }

  get_content(field) {
    if (!field || field.length === 0) return null;
    // abstract field render can be used for content from different (CMS) sources
    if (!!field.text) return field.text;
    // no extra work need
    return field;
  }
}
